import React from "react";
import { Link, useLocation } from "react-router-dom";

function HeaderArea({ storeName, storeLogo }) {
    const currentPath = useLocation().pathname;

    return (
        <>
            <div className="header-area" id="headerArea">
                <div className="container">
                    {/* Header Content*/}
                    <div className="header-content header-style-five header-style-three position-relative d-flex align-items-center justify-content-between">
                        {
                            /* Logo Wrapper*/
                            storeLogo && <div className="logo-wrapper">
                                <Link to="/">
                                    <img
                                        src={storeLogo}
                                        alt={storeLogo}
                                    />
                                    <span className="ms-2">{storeName}</span>
                                </Link>
                            </div>
                        }

                        {
                            /* Add btn to add new address when on the address/list component */
                            currentPath === "/address/list" && <div className="user-profile-wrapper">
                                <Link to={'/address/new'} className="custom-span custom-text-style" >Add new</Link>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderArea;
