import React from "react";
import RouterComponent from "./components/Router";

import { ModalProvider } from "./context/modalContext";
import { TopLoadingBarProvider } from "./context/topLoadingBarContext";
import { ServerProvider } from "./context/serverContext";
import { AuthProvider } from "./context/authContext";
import { CartItemProvider } from "./context/cartItemContext";
import { HelmetProvider } from "react-helmet-async";

function App() {
    return (
        <>
            <HelmetProvider >
                <ServerProvider>
                    <ModalProvider>
                        <AuthProvider>
                            <TopLoadingBarProvider>
                                <CartItemProvider>
                                    <RouterComponent />
                                </CartItemProvider>
                            </TopLoadingBarProvider>
                        </AuthProvider>
                    </ModalProvider>
                </ServerProvider>
            </HelmetProvider>
        </>
    );
}

export default App;
