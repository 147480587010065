import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function EmailVerification({ isEnabled, email, handleEmailChange, errorLabelForEmail, handleEmailSubmit }) {
    
    return (
        isEnabled ? <>
            <div>
                <h3>Email Verification</h3>
                <p className="mb-2">
                    We will send you an verification link to this email address.
                </p>
            </div>
            <div className="otp-form mt-3 px-2">
                <div className="form-group mb-2">
                    <input
                        className="form-control"
                        type="email"
                        value={email}
                        placeholder="demoemail@example.com"
                        onChange={(e) =>
                            handleEmailChange(e.target.value)
                        }
                    />
                    <label className="text-danger form-text mt-1 ms-1">
                        {errorLabelForEmail}
                    </label>
                </div>

                <button
                    className="btn custom-btn-style w-100"
                    type="button"
                    onClick={handleEmailSubmit}
                    disabled={email === "" || errorLabelForEmail ? true : false}
                >
                    Send verification Link
                </button>
            </div>
            <div className="login-meta-data px-2 text-center">
                <p className="mt-3 mb-2">
                    By providing my email, I hereby agree the
                    <Link
                        className="mx-1 stretched-link"
                        to="/terms-and-conditions"
                    >
                        Term of Services
                    </Link>
                    and
                    <Link
                        className="mx-1 stretched-link"
                        to="/privacy-policy"
                    >
                        Privacy Policy.
                    </Link>
                </p>
            </div>
        </> : ''
    )
}
