const confirmOtp = async (otp) => {
    const isOtpConfirm = await window.confirmationResult
        .confirm(otp)
        .then((res) => {
            //otp confirmed remove confirmationResult object and return otp confirmed as true
            window.confirmationResult = undefined;
            return true;
        })
        .catch((error) => {
            console.log('error: ', error);
            return false;
        });

    return isOtpConfirm;
};

export default confirmOtp;
