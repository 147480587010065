import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function PhoneVerification({ isEnabled, phoneNumber, isValidPhoneNumber, errorLabelForPhone, handleLoginWithPhone }) {
    
    return (
        isEnabled ? <>
            <div>
                <h3>Phone Verification</h3>
                <p className="mb-2">
                    We will send you an OTP on this phone number.
                </p>
            </div>
            <div className="otp-form mt-3 px-2">
                <div className="form-group mb-2">
                    <input
                        className="form-control"
                        type="tel"
                        value={phoneNumber}
                        placeholder="9999999999"
                        maxLength="10"
                        minLength="10"
                        onChange={(e) =>
                            isValidPhoneNumber(e.target.value)
                        }
                    />
                    <label className="text-danger form-text mt-1 ms-1">
                        {errorLabelForPhone}
                    </label>
                </div>

                <button
                    className="btn custom-btn-style w-100"
                    type="button"
                    onClick={handleLoginWithPhone}
                    disabled={
                        !/^[0-9]+$/.test(phoneNumber) ||
                            phoneNumber.length < 10
                            ? true
                            : false
                    }
                >
                    Send OTP Now
                </button>
            </div>
            <div className="login-meta-data px-2 text-center">
                <p className="mt-3 mb-2">
                    By providing my phone number, I hereby agree the
                    <Link
                        className="mx-1 stretched-link"
                        to="/terms-and-conditions"
                    >
                        Term of Services
                    </Link>
                    and
                    <Link
                        className="mx-1 stretched-link"
                        to="/privacy-policy"
                    >
                        Privacy Policy.
                    </Link>
                </p>
            </div>
        </> : ''
    )
}
