import React from 'react'

export default function VerifySignInLoading() {
    return (
        <div className='page-content-wrapper'>
            <div className='page-content-wrapper'>
                <div className="d-flex vh-100 align-items-center justify-content-center bg-light">
                    <div className="text-center text-white">
                        {/* Animated Spinner with Glow */}
                        <div className="spinner-grow accent-red mb-4" style={{ width: '2rem', height: '2rem' }} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        {/* Custom Text */}
                        <h4 className="fw-bold text-color">Verifying Your Sign-In</h4>
                        <p className="fs-6">This may take a few seconds. Please stay with us.</p>
                        {/* Animated Dots */}
                        <div className="mt-3">
                            <span className="dot-animation"></span>
                            <span className="dot-animation"></span>
                            <span className="dot-animation"></span>
                        </div>
                    </div>
                    <style>
                        {`
                .dot-animation {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                    background-color: var(--accent-red);
                    border-radius: 50%;
                    animation: bounce 1.5s infinite ease-in-out;
                }
                .dot-animation:nth-child(2) {
                    animation-delay: 0.3s;
                }
                .dot-animation:nth-child(3) {
                    animation-delay: 0.6s;
                }
                @keyframes bounce {
                    0%, 80%, 100% {
                        transform: scale(0);
                    }
                    40% {
                        transform: scale(1);
                    }
                }
                `}
                    </style>
                </div>
            </div>
        </div>
    )
}
