import React, { useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    getProductPageData,
    setFetchedProductsCategory,
    setLoadedPageNo,
    updateLoadedPageNo,
} from "../../redux/actions/productActions";

import { modalContext } from "../../context/modalContext";
import { serverContext } from "../../context/serverContext";

import Skeleton from "./Skeleton";
import ProductItem from "./ProductItem";
import NoProducts from "./NoProducts";
import { decrypt, isDecryptedDataValid } from "../functions/cipherFunctions";
import MetaTitles from "../MetaTitles";
import { getCategoryPageData } from "../../redux/actions/categoriesActions";
import NotFound from "../NotFound";

function Products() {
    const dispatch = useDispatch();


    const { categorySlug } = useParams();

    // Extract the Category ID from the categorySlug
    const categoryId = categorySlug.split("-").pop();

    // Remove categoryId from the URL
    const categoryUrlKey = categorySlug.replace(/-\d+$/, "");

    const { serverPath } = useContext(serverContext);
    const { showErrorDialog } = useContext(modalContext);


    //get category products from reducer
    const productsArrayFromStore = useSelector((state) => state.productReducer.productData);
    const noCategoryFound = useSelector((state) => state.productReducer.noCategoryFound);

    const loading = useSelector((state) => state.productReducer.loading);
    const exception = useSelector((state) => state.productReducer.exception);

    //get and decrypt categories from reducer
    const categoriesFromReducer = useSelector((state) => state.categoriesReducer.categoryData);
    const { Categories } = categoriesFromReducer && isDecryptedDataValid(decrypt(categoriesFromReducer, true));

    const currentCategoryName = Categories?.find(category => {
        return parseInt(category.CategoryId) === parseInt(categoryId)
    })?.CategoryName;

    //get loaded page of products from store
    const loadedProductsPagefromStore = useSelector(
        (state) => state.productReducer.loadedPageOfProducts
    );

    //function to get loaded page number of current category
    const getLoadedPageNo = () => {
        //if page already stored then get that page number
        if (loadedProductsPagefromStore.length !== 0) {
            //filter loaded page number of current category
            const loadedPage = loadedProductsPagefromStore.filter((obj) => {
                return parseInt(obj.categoryId) === parseInt(categoryId);
            });

            //after filter if loaded page found then return it or else return default page as 1
            if (loadedPage.length !== 0) return loadedPage[0].loadedPage;
            else return 1;
        } else return 1;
    };

    //get loaded page number by calling a getLoadedPageNo function
    const loadedPageNo = getLoadedPageNo();

    //reference variable to check whether to load more products or not
    const loadMoreProducts = useRef(false);

    const params = {
        categoryId: categoryId,
        categoryUrlKey: categoryUrlKey,
        pageNo: loadedPageNo,
        serverPath: serverPath,
    };

    //get category list if no current category name found
    useEffect(() => {
        if (!currentCategoryName) dispatch(getCategoryPageData(serverPath))

        //eslint-disable-next-line
    }, []);

    //get fetched products category id from redux store
    const alreadyFetchedProductsCategory = useSelector(
        (state) => state.productReducer.fetchedProductsCategories
    );

    //dispatch action to load products from api if not already fetched
    useEffect(() => {

        if (alreadyFetchedProductsCategory.length === 0 || alreadyFetchedProductsCategory.indexOf(categoryId) === -1) {

            //scroll to top only when no products found yet
            window.scrollTo(0, 0);

            dispatch(getProductPageData(params));
        }
        //eslint-disable-next-line
    }, []);

    //function to load more products when user click on load more button
    const fetchMoreProducts = (pageNumber) => {
        loadMoreProducts.current = true;
        //if user is offline then show error warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return false;
        }

        dispatch(
            getProductPageData({
                categoryId: categoryId,
                pageNo: parseInt(pageNumber) + 1,
                categoryUrlKey: categoryUrlKey,
                serverPath: serverPath,
            })
        );
    };



    if (exception) {
        showErrorDialog(
            exception.message,
            exception.description
        );

        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    //if more products loaded successfully then update number of loaded page in redux store
    if (!loading && !exception && loadMoreProducts.current === true) {
        dispatch(updateLoadedPageNo(categoryId, loadedPageNo + 1));
        loadMoreProducts.current = false;
    }

    const productList = [];
    let totalNumOfProducts = 0;
    let fetchedProductsCatgoryId = 0;

    if (productsArrayFromStore) {
        productsArrayFromStore.map((index) => {
            //check if current array index of productsArrayFromStore contains products of current category or not
            //because productsArrayFromStore contains products of multiple categories
            if (
                index.Products[0] &&
                index.Products[0]["CategoryId"] === categoryId
            ) {
                totalNumOfProducts = parseInt(index.TotalProducts);

                fetchedProductsCatgoryId = index.Products[0]["CategoryId"];

                //store products of current category in productList
                return index.Products.map((product) =>
                    productList.push(product)
                );
            } else return null;
        });
    }

    //store fetched category id And loaded page number in redux store if its not already stored
    if (
        fetchedProductsCatgoryId &&
        alreadyFetchedProductsCategory.indexOf(fetchedProductsCatgoryId) === -1
    ) {
        //set fetched products category id in redux store
        dispatch(setFetchedProductsCategory(fetchedProductsCatgoryId));

        //set loaded products page number in redux store
        dispatch(setLoadedPageNo(fetchedProductsCatgoryId, loadedPageNo));
    }

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={currentCategoryName ? (currentCategoryName + ' - products') : 'category product list'} description={currentCategoryName ? (currentCategoryName + ' - products') : 'category product list'} />

            {loadMoreProducts.current === false && loading ?
                <Skeleton /> :
                noCategoryFound ?
                    <NotFound /> :
                    totalNumOfProducts === 0 ?
                        <NoProducts /> :
                        <ProductItem
                            productList={productList}
                            totalNumOfProducts={totalNumOfProducts}
                            loadedPageNo={loadedPageNo}
                            fetchMoreProducts={fetchMoreProducts}
                        />
            }
        </>
    );
}

export default Products;
