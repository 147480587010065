import React, { useState } from 'react'

export default function PaymentMethods({ methods, handlePaymentTypeChange, selectedShippingType, selectedPaymentType }) {

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState( selectedPaymentType === "Razorpay" ? 'razorpay' : '');
    

    let methodsElement = '';
    if (methods.length > 0) {
        methodsElement = methods.map((items, index) => {
            
            // Check if COD is allowed for self-pickup orders; if not, don't display it
            if (selectedShippingType === 2 && items.method === "cod" && items?.allowSlefPickup === false) {
                return null;
            }

            return (
                <div key={`payment-${items.method}-${index}`} className="mb-3 border rounded p-3">
                    <div className="form-check d-flex align-items-center">
                        <input
                            className="form-check-input custom-form-check me-2"
                            type="radio"
                            name="paymentType"
                            id={items.method}
                            value={items.valueForCheckBox}
                            checked={selectedPaymentMethod === items.method || selectedPaymentType === items.valueForCheckBox}
                            onChange={(e) => {
                                handlePaymentTypeChange(e.target.value);
                                setSelectedPaymentMethod(items.method);
                            }}
                        />
                        <label className="form-check-label d-flex align-items-center w-100" htmlFor={items.method} style={{ cursor: 'pointer' }}>
                            <span className="mx-2">{items.frontLabel}</span>
                            {items?.paymentIcons?.length && (
                                <div className="ms-auto d-block align-items-center">
                                    {
                                        items.paymentIcons.map(item => {
                                            return <img alt={item.alt} src={item.path} key={item.alt} width="38" height="24" className="me-1" />
                                        })
                                    }
                                </div>
                            )}
                        </label>
                    </div>
                    {selectedPaymentMethod === items.method && (items.method === "razorpay" || selectedPaymentType === "Razorpay" )&& (
                        <div className="mt-3 p-3 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-270.8 371 102 52" className="responsive-svg">
                                <path fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2" d="M-182 404v16.8c0 .7-.4 1.2-1 1.2h-75.7c-.7 0-1.2-.6-1.2-1.2v-47.6c0-.7.6-1.2 1.2-1.2h75.7c.7 0 1 .6 1 1.2V395m-78-14h78m-17 18h27m-3.9-4.6 4.5 4.6-4.5 4.6"></path>
                                <circle cx="-255.5" cy="376.5" r="1.5" fill="currentColor"></circle>
                                <circle cx="-250.5" cy="376.5" r="1.5" fill="currentColor"></circle>
                                <circle cx="-245.5" cy="376.5" r="1.5" fill="currentColor"></circle>
                            </svg>
                            <p className="mb-0 mt-2">{items.frontMessage}</p>
                        </div>
                    )}
                </div>
            );
        });
    }

    //check if no payment element 
    if ((!methods?.length) || (methods?.NoMethods === true || methods === false)) {
        methodsElement = <h6 className="text-center text-danger">No payment methods available</h6>;
    }

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Payment Type
            </div>
            <div className='card-body'>
                {methodsElement}
            </div>
        </div>
    )
}
