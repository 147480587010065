import React, { useEffect } from 'react'

//import components
import PaymentProcessingDialog from '../popupDialogs/PaymentProcessingDialog';
import CancelationPoliciesDialog from '../popupDialogs/CancelationPoliciesDialog';
import OrderDetailsSkeleton from './OrderDetailsSkeleton';
import OrderStatusDetails from './OrderStatusDetails';
import OrderItems from './OrderItems';
import PaymentDetails from './PaymentDetails';
import ShippingAddress from './ShippingAddress';
import OrderNote from './OrderNote';
import OrderAmount from './OrderAmount';
import ConfirmOrderCancelation from '../popupDialogs/ConfirmOrderCancelation'
import CancelOrder from './CancelOrder';

import { useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { authContext } from '../../context/authContext';
import { serverContext } from '../../context/serverContext';
import { modalContext } from '../../context/modalContext';

import { clearOrderProcessReducersData, paymentProcess, paymentCanceled, revisePayment, updateFailedPaymentStatus, validatePayment, cancelOrder } from '../../redux/actions/orderProcessActions';
import { clearIsOrderDetailsFound, getOrderDetails, getOrderHistoryByOrderId, reFetchOrderDetails } from '../../redux/actions/orderDataActions';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import { addCreatedRefundCount, getNewCreatedRefund } from '../../redux/actions/refundActions';
import OrderStatusHistory from './OrderStatusHistory';
import MetaTitles from '../MetaTitles';


export default function OrderDetails() {

    const { orderId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const { serverPath, serverImagePath } = useContext(serverContext);
    const { isAuthenticate } = useContext(authContext);
    const { showErrorDialog, showSuccessDialog, showLoading, hideLoading } = useContext(modalContext);

    const isOrderProcessed = useRef(false);

    //get and decrypt order history data from reducer
    const getOrderHistoryData = useSelector(state => state.orderDataReducer.orderHistoryData);
    const orderHistoryList = getOrderHistoryData ? isDecryptedDataValid(decrypt(getOrderHistoryData, true)) : null;

    //get and decrypt order details data from reducer
    const getOrderDetailsFromReducer = useSelector(state => state.orderDataReducer.orderDetails);
    const orderDetailsData = getOrderDetailsFromReducer ? isDecryptedDataValid(decrypt(getOrderDetailsFromReducer, true)) : [];

    //get and decrypt refunds list
    const getRefundDataFromReducer = useSelector(state => state.refundReducer.refundData);
    const refundList = getRefundDataFromReducer ? isDecryptedDataValid(decrypt(getRefundDataFromReducer, true)) : null;

    //get and decrypt shipping configurations from reducer
    const getStoreConfigsData = useSelector(state => state.generalReducer.storeConfigs);
    const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

    //state to check if details are found for current requested order
    const isCurrentOrderDetailsFoundInReducer = useSelector(state => state.orderDataReducer.isOrderDetailsFound);

    //processed order for payment revise
    const processedOrder = useSelector((state) => state.orderProcessReducer.processedOrder);
    const createdOrder = processedOrder?.createdOrder;

    const placedOrder = useSelector((state) => state.orderProcessReducer.placedOrder);
    const paymentProcessing = useSelector(state => state.orderProcessReducer.paymentProcessing);
    const exception = useSelector(state => state.orderProcessReducer.exception);

    //filter current order history data
    const currentOrderHistory = orderHistoryList?.length > 0 && orderHistoryList.filter((obj) => {
        return parseInt(obj.OrderId) === parseInt(orderId);
    })[0];

    //filter current order details
    const currentOrderDetails = orderDetailsData?.length > 0 && orderDetailsData.filter((obj) => {
        return parseInt(obj.orderId) === parseInt(orderId);
    })[0]?.details;

    const isOrderCanceled = useSelector(state => state.orderProcessReducer.orderCanceled);

    //show cancelation policy pop-up
    const showCancelationPolicy = (e) => {

        e.preventDefault();

        return window.$("#cancelationPolicyModal").modal("show");
    }

    //show cancel order pop-up
    const showCancelOrderPopup = () => {
        window.$("#confirmOrderCancelationDialog").modal("show")
    }

    //function to hanle ordeer cancelation
    const processCancelOrder = (reasonForCancelation) => {
        const params = {
            orderId: orderId,
            userId: isAuthenticate?.userId,
            comment: reasonForCancelation.comment?.length > 1 ? reasonForCancelation.comment : reasonForCancelation.reason
        }

        window.$("#confirmOrderCancelationDialog").modal("hide")
        showLoading();

        dispatch(cancelOrder(serverPath, params));
    }

    //function to show payment processing pop-up until order processing and creating in server
    const showPaymentProcessingNotice = () => {
        window.$("#cancelationPolicyModal").modal("hide");

        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        dispatch(paymentProcess())

        //show payment processing dialog
        window.$("#paymentProcessingDialog").modal("show");

        //call initiatePayment function after 3 seconds to initiate payment
        setTimeout(() => {
            initiatePayment();
        }, 3000);
    }

    //function to initiate payment
    const initiatePayment = () => {

        const params = {
            userId: isAuthenticate?.userId,
            orderId: orderId
        }

        //call action to process order data in server
        dispatch(revisePayment(serverPath, params));

        isOrderProcessed.current = true;
    }

    //function to invoke razorpay pop-up
    const invokeRazorpay = () => {
        if (createdOrder?.OrderId && createdOrder?.Amount && createdOrder?.RzpOrderId && createdOrder?.KeyId) {
            const options = {
                "key": createdOrder?.KeyId,
                "amount": createdOrder?.Amount,
                "currency": "INR",
                "order_id": createdOrder?.RzpOrderId,
                "name": storeConfigs?.storeName,
                "description": "Thanks for your purchase",
                "image": storeConfigs?.storeLogo,
                //handler for if payment successfully processed
                "handler": function (response) {
                    //current order id
                    response.orderId = createdOrder?.OrderId;

                    //dispatch action to validate payment
                    dispatch(validatePayment(serverPath, response));

                    isOrderProcessed.current = false;
                },
                "prefill": {
                    "name": isAuthenticate.username,
                    "email": isAuthenticate.email || currentOrderDetails?.Address?.Email,
                    "contact": isAuthenticate.phoneNumber
                },
                "theme": {
                    "color": localStorage.getItem('theme') === 'dark' ? "#061238" : '#06227a'
                },
                "modal": {
                    //event listner to detect when customer close payment pop-up
                    "ondismiss": function () {
                        console.log('Payment pop-up closed');
                        dispatch(paymentCanceled());
                    }
                }
            };
            //show payment processing dialog
            window.$("#paymentProcessingDialog").modal("hide");

            const rzp = new window.Razorpay(options);
            rzp.open(options);

            //event listner for if payment fail
            rzp.on('payment.failed', function (response) {
                const params = {
                    orderId: createdOrder?.OrderId,
                    status: "Failed",
                    rzpOrderId: response.error?.metadata?.order_id,
                    rzpPaymentId: response.error?.metadata?.payment_id
                }

                //dispatch action to update payment status as failed for current order
                dispatch(updateFailedPaymentStatus(serverPath, params));

                isOrderProcessed.current = false;
                const reFetchHistory = true;

                //ReFetch current order details from server
                //we set delay of 1 seconds because it takes some time to clear current order details data from reducer
                setTimeout(function () {
                    dispatch(reFetchOrderDetails(serverPath, isAuthenticate?.userId, orderId, orderDetailsData));

                    if (currentOrderHistory)
                        dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, orderId, orderHistoryList, reFetchHistory));
                }, 1000);

                //clear order data from reducer
                dispatch(clearOrderProcessReducersData());
            });
        } else {
            showErrorDialog(
                'Internal server error',
                'Something went wrong, Please try again after some time'
            );
        }
    }

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //Redirect users to 404 page if no details found for requested order
    useEffect(() => {
        if (isCurrentOrderDetailsFoundInReducer === false) {
            history.push("/not-found/home")
            dispatch(clearIsOrderDetailsFound());
        }
        //eslint-disable-next-line
    }, [isCurrentOrderDetailsFoundInReducer]);

    //fetch order details from the server if already not fetched
    useEffect(() => {
        if (!currentOrderDetails && isAuthenticate?.userId) {
            dispatch(getOrderDetails(serverPath, isAuthenticate?.userId, orderId, orderDetailsData));
        }

        //eslint-disable-next-line
    }, [isAuthenticate]);

    //Monitor processedOrder to invoke razorpay pop-up to capture payment 
    //when order data fetched successfully from the server
    useEffect(() => {
        if (isOrderProcessed.current) {
            window.$("#paymentProcessingDialog").modal("hide");
            invokeRazorpay();
        }
        //eslint-disable-next-line
    }, [processedOrder])

    //Monitor placedOrder to show success pop-up if payment is captured 
    //and order completed while revising the payment
    useEffect(() => {
        if (placedOrder?.Success) {

            showSuccessDialog(
                'Payment success',
                `Payment received sucessfully for your order ${currentOrderDetails?.OrderDetails?.OrderNumber}`
            );

            //clear data from order reducer
            dispatch(clearOrderProcessReducersData());

            const reFetchHistory = true;

            //ReFetch current order details from server
            //we set delay of 1 seconds because it takes some time to clear current order details data from reducer
            setTimeout(function () {
                dispatch(reFetchOrderDetails(serverPath, isAuthenticate?.userId, orderId, orderDetailsData));

                if (currentOrderHistory)
                    dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, orderId, orderHistoryList, reFetchHistory));
            }, 1000);
        }
        //eslint-disable-next-line
    }, [placedOrder]);

    //moniter canceled order
    useEffect(() => {

        //True when order is canceled but getting issues while creating refund from server
        if (isOrderCanceled?.IsRefundCreated === false) {
            hideLoading();

            showSuccessDialog(
                'Your order has been canceled',
                isOrderCanceled?.Message
            );
        }
        else if (parseInt(isOrderCanceled?.OrderId) === parseInt(orderId)) {
            hideLoading();

            if (currentOrderDetails?.OrderDetails?.PaymentType === "Razorpay") {
                showSuccessDialog(
                    'Your order has been canceled',
                    'If this order is eligible for refund, The process will be initiated and your payment will be refunded within 5-7 days in the same account you used to pay.'
                );

                //fetch refund data created for canceled order
                if (refundList?.length > 0) {
                    dispatch(getNewCreatedRefund(serverPath, isAuthenticate?.userId, refundList));
                    dispatch(addCreatedRefundCount());

                }
            }
            else {
                showSuccessDialog(
                    'Your order has been canceled',
                    ''
                )
            }
        }

        //ReFetch current order details from server
        if (isOrderCanceled?.OrderId) {

            //clear data from order reducer
            dispatch(clearOrderProcessReducersData());

            //we set delay of 1 seconds because it takes some time to clear current order details data from reducer
            setTimeout(function () {
                dispatch(reFetchOrderDetails(serverPath, isAuthenticate?.userId, orderId, orderDetailsData));
            }, 1000);
        }

        //eslint-disable-next-line
    }, [isOrderCanceled]);


    //Show error dialog for any excetion generated
    if (exception) {
        hideLoading();
        window.$("#cancelationPolicyModal").modal("hide");
        window.$("#paymentProcessingDialog").modal("hide");

        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"Order details"} description={"Users order details"} />

            <CancelationPoliciesDialog history={history} checkoutCallback={showPaymentProcessingNotice} />
            <PaymentProcessingDialog />
            <ConfirmOrderCancelation callbackHnadler={processCancelOrder} />


            <div className='page-content-wrapper'>
                <div className="row justify-content-center">
                    {!currentOrderDetails ? <OrderDetailsSkeleton /> :
                        <>
                            <div className='col-sm-6'>
                                <OrderStatusDetails orderNumber={currentOrderDetails?.OrderDetails?.OrderNumber} orderDate={currentOrderDetails?.OrderDetails?.OrderDate} status={currentOrderDetails?.OrderDetails?.Status} shippingType={currentOrderDetails?.OrderDetails?.ShippingType} addressType={currentOrderDetails?.Address?.Type} />
                                <OrderItems items={currentOrderDetails?.OrderItems} serverImagePath={serverImagePath} />
                                <OrderStatusHistory orderStatusHistory={currentOrderDetails?.OrderStatusHistory} />
                            </div>

                            <div className='col-sm-6'>
                                <ShippingAddress address={currentOrderDetails?.Address} />
                                <PaymentDetails paymentDetails={currentOrderDetails?.PaymentDetails} paymentType={currentOrderDetails?.OrderDetails?.PaymentType} paymentStatus={currentOrderDetails?.OrderDetails?.PaymentStatus} noDetails={currentOrderDetails?.NoPaymentDetails} initiatePayment={showCancelationPolicy} paymentProcessing={paymentProcessing} orderStatus={currentOrderDetails?.OrderDetails?.Status} rzpRefundId={currentOrderDetails?.RefundData?.RzpRefundId} />
                                {currentOrderDetails?.OrderDetails?.OrderNote && <OrderNote orderNote={currentOrderDetails?.OrderDetails?.OrderNote} />}
                                <OrderAmount subTotal={currentOrderDetails?.OrderDetails?.SubTotal} discount={currentOrderDetails?.OrderDetails?.Discount} deliveryCharge={currentOrderDetails?.OrderDetails?.ShippingCharge} taxAndCharges={currentOrderDetails?.OrderDetails?.TaxAndCharges} totalPayableAmount={currentOrderDetails?.OrderDetails?.Total} testOrderAmount={currentOrderDetails?.OrderDetails?.TestOrderAmount} />
                                {(parseInt(currentOrderDetails?.OrderDetails?.Status) === 1 || parseInt(currentOrderDetails?.OrderDetails?.Status) === 0 || parseInt(currentOrderDetails?.OrderDetails?.Status) === -1) && <CancelOrder orderStatus={currentOrderDetails?.OrderDetails?.Status} showCancelOrderPopup={showCancelOrderPopup} refundData={currentOrderDetails?.RefundData} />}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}