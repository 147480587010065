import React from "react";

const getBrowserInstructions = () => {

    let userAgent = navigator.userAgentData?.brands[0]?.brand;
    const site = window.location.origin;

    if (!userAgent)
        userAgent = navigator.userAgent;


    if (userAgent.includes("Chrome") && userAgent.includes("Mobile")) {
        return {
            browser: "Chrome (Mobile)",
            steps: [
                "Open Chrome on your mobile device.",
                "Tap the three dots in the top-right corner to open the menu.",
                "Go to Settings > Privacy and Security > Clear browsing data.",
                "Select the data types to clear and tap 'Clear data'."
            ]
        };
    } else if (userAgent.includes("Chrome")) {
        return {
            browser: "Chrome (Desktop)",
            steps: [
                "Go to Settings.",
                "Navigate to Privacy and Security > Site Settings > View Permissions and Data Stored Across Sites.",
                `Select ${site} and clear data.`
            ]
        };
    } else if (userAgent.includes("Firefox") && userAgent.includes("Mobile")) {
        return {
            browser: "Firefox (Mobile)",
            steps: [
                "Open Firefox on your mobile device.",
                "Tap the menu button (three lines or dots) and go to Settings.",
                "Navigate to Privacy and Security > Clear Private Data.",
                "Select the data types to clear and tap 'Clear data'."
            ]
        };
    } else if (userAgent.includes("Firefox")) {
        return {
            browser: "Firefox (Desktop)",
            steps: [
                "Open Settings > Privacy & Security.",
                "Scroll to Cookies and Site Data > Manage Data.",
                `Search for ${site} and remove its data.`
            ]
        };
    } else if (userAgent.includes("Safari") && userAgent.includes("Mobile")) {
        return {
            browser: "Safari (Mobile)",
            steps: [
                "Open the Settings app on your iPhone or iPad.",
                "Scroll down and select Safari.",
                "Tap 'Clear History and Website Data'."
            ]
        };
    } else if (userAgent.includes("Safari")) {
        return {
            browser: "Safari (Desktop)",
            steps: [
                "Open Preferences > Privacy.",
                "Click \"Manage Website Data.\"",
                `Search for ${site} and remove its data.`
            ]
        };
    } else if (userAgent.includes("Edge")) {
        return {
            browser: "Edge",
            steps: [
                "Go to Settings > Cookies and site permissions.",
                "Click \"Manage and delete cookies and site data.\"",
                `Find ${site} and clear its data.`
            ]
        };
    } else {
        return {
            browser: "Unknown Browser",
            steps: ["Please refer to your browser's help documentation to clear site data."]
        };
    }

};

const BrowserInstructions = () => {
    const { browser, steps } = getBrowserInstructions();

    return (
        <div className="page-content-wrapper">
            <div className="container-fluid1 py-1">
                <div className="card shadow">
                    <div className="card-header">
                        <h5 className="mb-0">How to Clear Site Data</h5>
                    </div>
                    <div className="card-body">
                        <h6 className="text-secondary">Browser Detected: {browser}</h6>
                        <ol className="mt-3">
                            {steps.map((step, index) => (
                                <li key={index} className="mb-2">
                                    {step}
                                </li>
                            ))}
                        </ol>
                        <div className="alert alert-info mt-3">
                            If these instructions don’t match your browser, please consult its documentation.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrowserInstructions;
