import {
    BEGIN_ACTION_IN_GENERAL_REDUCER,
    EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
    SUCCESS_GET_STORE_CONFIGS,
    SUCCESS_GET_ABOUT_US_STATUS,
    SUCCESS_GET_ABOUT_US_CONTENT,
    SUCCESS_GET_PRIVACY_POLICY_CONTENT,
    SUCCESS_GET_TERMS_AND_CONDITIONS_CONTENT,
    SUCCESS_GET_STORE_CACHE_STATE,

} from "../constants";

const initialState = {
    loading: false,
    exception: false,
    storeConfigs: false,
    cacheState: false,
    isAboutUsEnabled: false,
    aboutUsContent: false,
    privacyPolicyContent: false,
    termsAndConditionContent: false,

};

const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_GENERAL_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case SUCCESS_GET_STORE_CONFIGS:
            return {
                ...state,
                loading: false,
                exception: false,
                storeConfigs: action.payload
            }

        case SUCCESS_GET_STORE_CACHE_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
                cacheState: action.payload
            }

        case SUCCESS_GET_ABOUT_US_STATUS:
            return {
                ...state,
                loading: false,
                exception: false,
                isAboutUsEnabled: action.payload?.Status
            }

        case SUCCESS_GET_ABOUT_US_CONTENT:
            return {
                ...state,
                loading: false,
                exception: false,
                isAboutUsEnabled: action.payload.Status,
                aboutUsContent: action.payload.Content
            }

        case SUCCESS_GET_PRIVACY_POLICY_CONTENT:
            return {
                ...state,
                loading: false,
                exception: false,
                privacyPolicyContent: action.payload.Content
            }

        case SUCCESS_GET_TERMS_AND_CONDITIONS_CONTENT:
            return {
                ...state,
                loading: false,
                exception: false,
                termsAndConditionContent: action.payload.Content
            }

        case EXCEPTION_GENERATED_IN_GENERAL_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload,
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
};

export default generalReducer;
