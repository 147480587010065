import React, { useEffect } from 'react'

import { useContext } from 'react';
import { authContext } from '../../context/authContext';
import { serverContext } from '../../context/serverContext';
import RefundItems from './RefundItems';
import RefundSkeleton from './RefundSkeletion';
import { useDispatch, useSelector } from 'react-redux';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import { modalContext } from '../../context/modalContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getRefunds, loadMoreRefunds } from '../../redux/actions/refundActions';
import MetaTitles from '../MetaTitles';
import NoRefunds from './NoRefunds';

export default function Refunds() {

    const dispatch = useDispatch();
    const { isAuthenticate } = useContext(authContext);
    const { serverPath } = useContext(serverContext);
    const { showErrorDialog } = useContext(modalContext);

    const getRefundDataFromReducer = useSelector(state => state.refundReducer.refundData);
    const refundList = getRefundDataFromReducer ? isDecryptedDataValid(decrypt(getRefundDataFromReducer, true)) : null;
    const countTotalRefunds = useSelector(state => state.refundReducer.countTotalRefunds);
    const countCreatedRefunds = useSelector(state => state.refundReducer.countCreatedRefunds);
    const fetchedPageNo = useSelector(state => state.refundReducer.pageNoOffetchedRedunds);
    const loading = useSelector(state => state.refundReducer.loading);
    const exception = useSelector(state => state.refundReducer.exception);

    //function to load more refunds
    const loadMoreRefundsData = () => {
        const pageNo = fetchedPageNo + 1;

        dispatch(loadMoreRefunds(serverPath, isAuthenticate?.userId, refundList, pageNo, countCreatedRefunds))
    }

    //fetch refunds data from server if already not fetched
    useEffect(() => {
        if (isAuthenticate?.userId) {
            if (refundList === false || refundList === null)
                dispatch(getRefunds(serverPath, isAuthenticate?.userId));
        }

        //eslint-disable-next-line
    }, [isAuthenticate]);

    //scroll to top only when no refund found
    useEffect(() => {
        if (!refundList?.length)
            window.scrollTo(0, 0);
    }, [refundList])

    //Show error dialog for any excetion generated
    if (exception) {

        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"Order refunds"} description={"Users order refund list"} />

            {!loading && isAuthenticate?.userId && parseInt(countTotalRefunds) <= 0 ? <NoRefunds /> :
                <div className="page-content-wrapper">
                    <div className="row justify-content-center">
                        {
                            !refundList?.length && (loading || !isAuthenticate?.userId) ? <RefundSkeleton /> :
                                <>
                                    <InfiniteScroll
                                        scrollThreshold={0.9}
                                        dataLength={
                                            Array.isArray(refundList)
                                                ? refundList.length
                                                : 0
                                        }
                                        next={loadMoreRefundsData}
                                        hasMore={
                                            Array.isArray(refundList) &&
                                            parseInt(refundList.length) !==
                                            parseInt(countTotalRefunds)
                                        }
                                        loader={<RefundSkeleton loadMore={true} />}
                                    >
                                        <div className='row'>
                                            <RefundItems refundItems={refundList} />
                                        </div>
                                    </InfiniteScroll>
                                </>
                        }
                    </div>
                </div>
            }
        </>
    )
}
