import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from "./rootReducer";

// configuration object for redux-persist
const persistConfig = {
  key: 'primary',
  storage,
  whitelist:['orderProcessReducer'],
}

// create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

//redux devtool config
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//create redux store with persistedReducer
const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunk))
);

//create the persisted store
const persistor = persistStore(store);

export { store, persistor };