import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { serverContext } from '../context/serverContext';
import { modalContext } from '../context/modalContext';
import { getAboutUsContent } from '../redux/actions/generalActions';
import { sanitize } from 'dompurify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import NotFound from './NotFound';
import MetaTitles from './MetaTitles';

const AboutSkeleton = () => {
    return (
        <div className='card'>
            <div className='card-body'>

                <SkeletonTheme highlightColor="#bababa">
                    <Skeleton height={30} width={"30%"} />
                    <Skeleton height={30} width={"50%"} count={2} className='d-block my-2' />
                    <Skeleton height={100} count={2} />

                    <Skeleton height={30} width={"30%"} />
                    <Skeleton height={30} width={"50%"} count={2} className='d-block my-2' />
                    <Skeleton height={100} count={2} />

                    <Skeleton height={30} width={"30%"} />
                    <Skeleton height={30} width={"50%"} count={2} className='d-block my-2' />
                    <Skeleton height={100} count={2} />

                    <Skeleton height={30} width={"30%"} />
                    <Skeleton height={30} width={"50%"} count={2} className='d-block my-2' />
                    <Skeleton height={100} count={2} />

                    <Skeleton height={30} width={"30%"} />
                    <Skeleton height={30} width={"50%"} count={2} className='d-block my-2' />
                    <Skeleton height={100} count={2} />

                </SkeletonTheme>
            </div>
        </div>
    )
}

export default function AboutUs() {

    const dispatch = useDispatch();
    const { serverPath } = useContext(serverContext);
    const { showErrorDialog } = useContext(modalContext);

    const isAboutUsEnabled = useSelector(state => state.generalReducer.isAboutUsEnabled);
    const aboutUsContent = useSelector(state => state.generalReducer.aboutUsContent);

    const loading = useSelector(state => state.generalReducer.loading);
    const exception = useSelector(state => state.generalReducer.exception);


    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    useEffect(() => {
        if (!aboutUsContent && aboutUsContent !== null) dispatch(getAboutUsContent(serverPath));
        //eslint-disable-next-line
    }, [])

    if (exception) {
        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"About us"} description={"About us"} />

            <div className="page-content-wrapper">
                {loading ?
                    <AboutSkeleton /> :
                    aboutUsContent === null || !isAboutUsEnabled ?
                        <NotFound /> :
                        <div className='card'>
                            <div className='card-body' style={{minHeight:"90vh"}}>
                                <div dangerouslySetInnerHTML={{ __html: sanitize(aboutUsContent) }}></div>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}
