import React, { createContext, useContext, useState, useEffect } from "react";
import {
    onAuthStateChanged,
    signOut,
    deleteUser,
} from "firebase/auth";
import { serverContext } from "../context/serverContext";
import { modalContext } from "../context/modalContext";
import axios from "axios";
import { decrypt, hasJsonStructure, isDecryptedDataValid } from "../components/functions/cipherFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getShipingConfigs } from "../redux/actions/orderDataActions";
import { getCacheState, getStoreConfigs } from "../redux/actions/generalActions";
import { auth, initializeFirebase } from "../components/functions/firebase/firebaseConfig";

export const authContext = createContext("authContext");

//function to clean browser cache and cookies
const cleanCacheAndCookies = () => {
    // Clear Cookies
    document.cookie.split(";").forEach((cookie) => {
        const [name] = cookie.split("=");
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    // Optionally clear service worker cache
    if ('caches' in window) {
        caches.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
        });
    }
}

export const AuthProvider = (props) => {

    const dispatch = useDispatch();

    const { serverPath } = useContext(serverContext);
    const { showErrorDialog, showLoading, hideLoading } = useContext(modalContext);

    const [isFirebaseReady, setIsFirebaseReady] = useState(false);
    const [isAuthenticate, setIsAuthenticate] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");

    //get and decrypt shipping configurations from reducer
    const getShippingConfigsData = useSelector(state => state.orderDataReducer.shippingConfigs);
    const shippingConfigs = getShippingConfigsData && isDecryptedDataValid(decrypt(getShippingConfigsData, true));

    //get and decrypt shipping configurations from reducer
    const getStoreConfigsData = useSelector(state => state.generalReducer.storeConfigs);
    const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

    //get and decrypt shipping configurations from reducer
    const getStoreCacheState = useSelector(state => state.generalReducer.cacheState);
    const cacheState = getStoreCacheState && isDecryptedDataValid(decrypt(getStoreCacheState, true));

    //fetch firebase config and initilize firebase app
    useEffect(() => {
        const initFirebase = async () => {
            await initializeFirebase(serverPath);
            setIsFirebaseReady(true);
        };

        initFirebase();
    }, [serverPath]);


    //fetch cahce state and shipping and store configs if not already fetched
    useEffect(() => {
        if (!shippingConfigs)
            dispatch(getShipingConfigs(serverPath));

        if (!storeConfigs)
            dispatch(getStoreConfigs(serverPath));

        if (!cacheState)
            dispatch(getCacheState(serverPath));

        //eslint-disable-next-line
    }, [])

    //flush cache if cache state updated
    useEffect(() => {
        //get users last cache flushed state
        const getUsersLastFlushedState = window.localStorage.getItem("lastCacheFlushed");

        //clear cache if the cache state is updated and users last flushed state is outdated
        if (cacheState?.State && cacheState?.State !== getUsersLastFlushedState) {
            console.log("Users cache cleaned");
            cleanCacheAndCookies(); //call function to clean
            window.localStorage.setItem("lastCacheFlushed", cacheState?.State); //store state in localstorage
        }

    }, [cacheState])

    useEffect(() => {
        if (isFirebaseReady && !isAuthenticate) {
            //event listner to get logged in users data
            onAuthStateChanged(auth, (user) => {
                //if users data found then get users data and authenticate else set isAuthenticate = false
                if (user) {
                    const userData = user.providerData[0];

                    //get users data from either providerData object or user object 
                    const username = userData.displayName !== null ? userData.displayName : user.displayName;
                    const email = userData.email !== null ? userData.email : user.email;
                    const phoneNumber = userData.phoneNumber !== null ? userData.phoneNumber : user.phoneNumber;

                    let loginUsing = '';

                    if (userData.providerId === "phone")
                        loginUsing = "Phone";
                    else if (userData.providerId === "password")
                        loginUsing = "Email"
                    else if (userData.providerId === "google.com")
                        loginUsing = "Google"
                    else
                        loginUsing = "Facebook";

                    const params = {
                        username: username,
                        email: email,
                        phoneNumber: phoneNumber,
                        loginUsing: loginUsing
                    };

                    //call function to authenticate user from server
                    authUser(params);
                }
                else
                    setIsAuthenticate(false);
            });
        }
        //eslint-disable-next-line
    }, [isFirebaseReady]);


    //authenticate user and get users data from server
    const authUser = async (params) => {
        showLoading();

        //check for neither email nor phone number shoul be null
        //this condition will be true only when user login with facebook using phone number
        //and user does not have linked email address with it.
        //because we can't get users phone number from facebook login
        if (params.email === null && params.phoneNumber === null) {
            hideLoading();
            showErrorDialog(
                "Unable to login with Facebook",
                "You are not able to login with this Facebook account, We didn't find required information from your account Please try to login again using Phone number or Google<br /><a href='/help/facebook/login'>Click here to know more</a>"
            );

            //Delete Users account from firebase
            const user = auth.currentUser;

            deleteUser(user)
                .then(() => {
                    console.log("User account removed");
                })
                .catch((error) => {
                    console.log(error);
                });

            return;
        } else {
            await axios
                .post(serverPath + "/auth-users", params)
                .then((res) => {
                    setIsAuthenticate({
                        userId: res.data.UserData.UserId,
                        username: res.data.UserData.Username,
                        email: res.data.UserData.Email ? res.data.UserData.Email : '',
                        phoneNumber: res.data.UserData.PhoneNumber ? res.data.UserData.PhoneNumber : '',
                        loginWith: res.data.UserData.LoginFrom,
                        isTestUser: res.data.IsTestUser,
                        defaultAddress: res.data.DefaultAddress !== null ? res.data.DefaultAddress : null
                    });
                })
                .catch((error) => {
                    if (error.request) {
                        const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''

                        //when user is blocked or failed because of duplicate account creation
                        if (error.request.status === 400) {

                            //logout users firebase sessions
                            logOut();

                            showErrorDialog(
                                errorText.ErrorMessage,
                                errorText?.ErrorDescription
                            );

                            if (errorText.DuplicateAccount === true) {

                                //delete duplicate user account from firebase when user try to create multiple account with same credantials,
                                //firebase always create multiple account with same credantials because we enabled multiple account settings in
                                //firebase and firebase does not provide valid error message for this problem
                                //and we created custome error message above to let our users to know that what actual error is.
                                const user = auth.currentUser;

                                deleteUser(user)
                                    .then(() => {
                                        console.log("Dublicate account removed");
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        } else if (error.request.status === 500)
                            showErrorDialog(
                                errorText.ErrorMessage,
                                "Something went wrong,</br>Please try to login again"
                            );
                        else
                            showErrorDialog(error.message, "Something went wrong");
                    } else
                        showErrorDialog(error.message, "Something went wrong");

                    setIsAuthenticate(false);
                });
        }
        hideLoading();
    };

    //log out user
    const logOut = async () => {

        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        const isSignOut = await signOut(auth)
            .then(() => {
                return true;
            })
            .catch((error) => {
                console.log(error);
                return false;
            });

        if (isSignOut === true) {
            setIsAuthenticate(false);
            return true;
        } else return false;
    };

    return (
        <authContext.Provider
            value={{
                isAuthenticate,
                setIsAuthenticate,
                phoneNumber,
                setPhoneNumber,
                authUser,
                logOut,
            }}
        >
            {props.children}
        </authContext.Provider>
    );
};
