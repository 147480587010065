import React from 'react';

export default function EmailInputForm({ email, handleEmailChange, error, handleEmailSubmit }) {

    return (
        <div className="d-flex vh-100 align-items-center justify-content-center bg-light">
            <div className="p-4 bg-white shadow rounded text-center" style={{ maxWidth: '400px', width: '100%' }}>
                <h4 className="fw-bold mb-3">Verify Your Email</h4>
                <p className="text-muted mb-4">
                    For security, please confirm the email address associated with your account. If possible, we
                    recommend completing this process on the device where you initiated the sign-in.
                </p>
                <form>
                    <div className="mb-3">
                        <input
                            type="email"
                            className={`form-control ${error ? 'is-invalid' : ''}`}
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => handleEmailChange(e.target.value)}
                        />
                        {error && <div className="invalid-feedback">{error}</div>}
                    </div>
                    <button type="button" className="btn btn-primary w-100" onClick={() => handleEmailSubmit()} disabled={error || !email ? true : false}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}
