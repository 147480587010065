import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux';
import { decrypt, isDecryptedDataValid } from './functions/cipherFunctions';

export default function MetaTitles({ title, description }) {

    //get and decrypt shipping configurations from reducer
    const getStoreConfigsData = useSelector(state => state.generalReducer.storeConfigs);
    const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

    return (
        <Helmet prioritizeSeoTags>
            <title>{title || ''}{storeConfigs?.storeName ? ` | ${storeConfigs?.storeName}` : ''}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={`${title || ''}${storeConfigs?.storeName ? ` | ${storeConfigs.storeName}` : ''}`} />
            <meta property="og:description" content={description} />
        </Helmet>
    )
}
