import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    getProductDetailsData,
    getMoreReviews,
    updateLoadedReviewsPageNumber,
    postUsersRatingAndReview,
    checkIsUserAlreadyPostedReview,
} from "../../redux/actions/productDetailsActions";

import { modalContext } from "../../context/modalContext";
import { serverContext } from "../../context/serverContext";
import { authContext } from "../../context/authContext";

//import page components
import Skeleton from "./Skeleton";
import RatingModal from "./RatingModal";
import ProductData from "./ProductData";
import AverageRating from "./AverageRating";
import TopReview from "./TopReview";
import GetUsersReview from "./GetUsersReview";
import AllReview from "./AllReview";
import MetaTitles from "../MetaTitles";
import NotFound from "../NotFound";

function ProductDetails() {
    const dispatch = useDispatch();

    const { productSlug } = useParams();

    // Extract the Product ID from the productSlug
    const productId = productSlug.split("-").pop();

    // Remove productId from the URL
    const productUrlKey = productSlug.replace(/-\d+$/, "");

    const { showErrorDialog, showSuccessDialog, showLoading, hideLoading } =
        useContext(modalContext);
    const { serverPath } = useContext(serverContext);
    const { isAuthenticate } = useContext(authContext);

    //get loaded page number of products reviews from store
    const loadedReviewsPageNofromStore = useSelector(
        (state) => state.productDetailsReducer.loadedReviewsPageNumber
    );

    //filter loaded page number of current product reviews
    let getLoadedPageNumber = loadedReviewsPageNofromStore.filter(
        (obj) => parseInt(obj?.ProductId) === parseInt(productId)
    )[0];

    //if loaded page number is null then assig it as page 1
    if (!getLoadedPageNumber) getLoadedPageNumber = 1;
    else getLoadedPageNumber = getLoadedPageNumber.LoadedPageNumber;

    //initial rating star and its status
    //we will pass this value to rating Modal component
    const [selectedStarRating, setSelectedStarRating] = useState({
        Status: "Rating is required",
        Star: 0,
    });

    //referance to check is users review is posting or not
    //we assigned its value as undefined so  full page skeleton display only on first render
    //and prevent it from  load every time when user post new rating and review
    const postedRating = useRef(undefined);

    //refrece variable to check whether to load more reviews or not
    const loadReviews = useRef(undefined);

    const firstRender = useRef(true);

    const isProductDataReFetched = useRef(false);

    const params = {
        serverPath: serverPath,
        productId: productId,
        productUrlKey: productUrlKey
    };

    //Get list of products data (reviews,ratings etc.)from redux store if available
    const productsData = useSelector(
        (state) => state.productDetailsReducer.productData
    );

    const productImages = useSelector(
        (state) => state.productDetailsReducer.productImages
    );
    const productConfigs = useSelector(
        (state) => state.productDetailsReducer.productConfigs
    );
    const productsReviews = useSelector(
        (state) => state.productDetailsReducer.reviews
    );
    const productsRatings = useSelector(
        (state) => state.productDetailsReducer.ratings
    );

    //get status of is user already posted review for current product or not
    const isUserAlreadyPostedReview = useSelector(
        (state) => state.productDetailsReducer.isUserAlreadyPostedReview
    );

    const exception = useSelector(
        (state) => state.productDetailsReducer.exception
    );
    const loading = useSelector((state) => state.productDetailsReducer.loading);

    //function to check if current products data is already fetched from api or not
    const alreadyFetchedProductData = () => {
        for (let i = 0; i < productsData.length; i++) {
            if (parseInt(productsData[i]?.ProductId) === parseInt(productId))
                return true;
        }
    };

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //fetch product details data if already not fetched
        if (!alreadyFetchedProductData() && firstRender.current === true) {
            dispatch(getProductDetailsData(params));

            firstRender.current = false;
        }

        //fetch status to check is user already posted review for this product or not
        if (isAuthenticate)
            dispatch(
                checkIsUserAlreadyPostedReview(serverPath, {
                    userId: isAuthenticate?.userId,
                    productId: productId,
                })
            );

        //eslint-disable-next-line
    }, [isAuthenticate, postedRating.current]);

    //function to load more Reviews  in reviews component
    //we passed this function to reviews components as props
    const loadMoreReviews = () => {
        //if user is offline then show error warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return false;
        }

        loadReviews.current = true;

        const params = {
            serverPath: serverPath,
            productId: productId,
            pageNo: parseInt(getLoadedPageNumber) + 1,
        };

        //dispatch action creater to load more reviews
        dispatch(getMoreReviews(params));
    };

    //function to set rating star and status
    //we passed this function to Get user Rating component
    const setRatingStarAndStatus = (object) => setSelectedStarRating(object);

    //function to post users rating and reviews
    //we passed this function to RatingModal component
    const submitUserRatingAndReview = (rating, review) => {
        const params = {
            userId: isAuthenticate?.userId,
            productId: productId,
            rating: rating,
            review: review,
        };

        window.$("#postRatingModal").modal("hide");

        //if user is offline then show error warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return false;
        }

        postedRating.current = true;

        //show loading
        showLoading();

        //dispatch action creater to post users rating and review
        dispatch(postUsersRatingAndReview(serverPath, params));
    };


    if (exception) {
        showErrorDialog(exception.message, exception.description);

        //hide loading
        hideLoading();

        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    //if there is no error and exception while loading more reviews then update loaded page number in store
    if (!loading && !exception && loadReviews.current === true) {
        loadReviews.current = false;

        dispatch(
            updateLoadedReviewsPageNumber({
                ProductId: productId,
                LoadedPageNumber: getLoadedPageNumber + 1,
            })
        );
    }

    //if users reviews and rating posted successfully
    //then Re-fetch current products data
    //to show updated reviews and ratings
    if (!loading && !exception && postedRating.current === true) {

        isProductDataReFetched.current = true;
        postedRating.current = false;

        //dispatch action creater to fetch products data
        dispatch(
            getProductDetailsData(params)
        );

        //hide loading
        hideLoading();

        //show success dialog
        showSuccessDialog(
            "Thank you",
            "Your Rating and review are posted successfully."
        );
    }

    //we store multiple products data in redux store as array of objects
    //so we have to filter current products data from list of data that we get from redux store
    const currentProductDetails = productsData.find((obj) => {
        return parseInt(obj?.ProductId) === parseInt(productId);
    });

    const currentProductReviewData = productsReviews.find((obj) => {
        return parseInt(obj?.ProductId) === parseInt(productId);
    });

    const currentProductRatings = productsRatings.find((obj) => {
        return parseInt(obj?.ProductId) === parseInt(productId);
    });

    const currentProductImages = productImages.find((obj) => {
        return parseInt(obj?.ProductId) === parseInt(productId);
    });

    const currentProductConfigs = productConfigs.find((obj) => {
        return parseInt(obj?.ProductId) === parseInt(productId);
    });



    let productDetails = "";
    let ratings = "";
    let topReviews = "";
    let reviews = "";
    let totalReviews = "";

    if (currentProductDetails) {
        productDetails = currentProductDetails;
        ratings = currentProductRatings;
        //top reviews temporarly removed
        //topReviews = currentProductReviewData.TopReviews;
        reviews = currentProductReviewData.Reviews;
        totalReviews = currentProductReviewData.TotalReviews;
    }





    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={productDetails?.ProductName} description={`${productDetails?.ProductName} - details`} />

            <RatingModal
                selectedStarRating={selectedStarRating}
                submitUserRatingAndReview={submitUserRatingAndReview}
            />
            {
                !loading && !exception && !currentProductDetails && !firstRender.current && !isProductDataReFetched.current ?
                    <NotFound /> :
                    <div className="page-content-wrapper py-1">
                        <div className="row justify-content-center">
                            {loading &&
                                loadReviews.current === undefined &&
                                postedRating.current === undefined ? (
                                <Skeleton />
                            ) : (
                                <>
                                    <ProductData productDetails={productDetails} productConfig={currentProductConfigs} productImages={currentProductImages} totalNumberOfReviews={totalReviews} averageRatings={ratings?.AverageRating} />
                                    <AverageRating ratings={ratings} />
                                    {
                                        /* show component to post rating and review if user is authenticated 
                                           and user don't have posted already
                                        */
                                        isAuthenticate &&
                                        isUserAlreadyPostedReview === false && (
                                            <GetUsersReview
                                                setRatingStarAndStatus={
                                                    setRatingStarAndStatus
                                                }
                                            />
                                        )
                                    }
                                    {/* Render top reviews component only when top reviews are available*/}
                                    {topReviews.length !== 0 && <TopReview topReviews={topReviews} />}
                                    <AllReview
                                        reviews={reviews}
                                        totalNumberOfReviews={totalReviews}
                                        loadMoreReviews={loadMoreReviews}
                                        loadedPageNumberOfReviews={getLoadedPageNumber}
                                        isLoading={loading}
                                    />
                                </>
                            )}
                        </div>
                    </div>
            }
        </>
    );
}

export default ProductDetails;
