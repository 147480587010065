import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { serverContext } from "../../context/serverContext";

function FeaturedCategory(props) {
    const { serverImagePath } = useContext(serverContext);

    const imagePath = serverImagePath + "/category/";
    let categoryList = null;

    if (Array.isArray(props.categories)) {
        categoryList = props.categories.map((val) => {

            const slug = `${val.UrlKey}-${val.CategoryId}`;
            return (
                <div
                    className="col-3 col-lg-2"
                    key={`featuredCategory-${val.CategoryId}`}
                >
                    <Link to={`/category/${slug}`}>
                        <div className="feature-card text-center">
                            <div className="card shadow mx-auto">
                                <img src={`${imagePath}${val.CategoryImage}`} alt="" />
                            </div>
                            <p className="mb-0 text-color">{val.CategoryName}</p>
                        </div>
                    </Link>
                </div>
            );
        });
    }

    return (
        <>
            <div className="py-4">
                <div className="container-fluid">
                    <strong className="text-color">Order by Categories</strong>
                    <div className="row g-3 mt-1">
                        {categoryList !== null ? (
                            <>
                                {categoryList}
                                <div className="col-3 col-md-3 col-lg-2">
                                    <div className="feature-card mx-auto text-center">
                                        <Link to="/categories">
                                            <div className="card shadow mx-auto">
                                                <i className="fa fa-arrow-right fa-1x text-color"></i>
                                            </div>
                                            <p className="mb-0 text-color">See all</p>
                                        </Link>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <h6 className="text-danger text-center pt-3">
                                No category found
                            </h6>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedCategory;
