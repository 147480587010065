import {
    signInWithPopup, GoogleAuthProvider, setPersistence, browserLocalPersistence,
} from "firebase/auth";

import { auth } from "./firebaseConfig";

const googleLoginWithPopup = (setIsInitiatedSingIn) => {

    const provider = new GoogleAuthProvider();

    setPersistence(auth, browserLocalPersistence)
        .then(() => {

            signInWithPopup(auth, provider)
                .then((result) => {
                    setIsInitiatedSingIn(false)
                    return true
                }).catch((error) => {
                    console.log('error: ', error);
                    setIsInitiatedSingIn(false)
                    return false;
                });
        })
        .catch((error) => {
            console.log(error);
            setIsInitiatedSingIn(false)
        });


}

export default googleLoginWithPopup;