import React from 'react'

export default function AddressFields(props) {
    return (
        <>
            <div className="container">
                <div className="form-group mt-2">
                    <textarea className="form-control" name="fullAddress" disabled={true} value={props.userAddress.fullAddress} />
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" name="houseFlatBlock" value={props.userAddress.houseFlatBlock} onChange={(e) => props.handleChangeHouseFlatBlock(e.target.value)} placeholder="HOUSE / FLAT / BLOCK NO." />
                    {
                        props.errorLabelForHouseFlatBlock &&
                        <label className='form-lable text-danger' htmlFor="houseFlatBlock">
                            <small>{props.errorLabelForHouseFlatBlock}</small>
                        </label>
                    }
                </div>

                <div className="form-group">
                    <input type="text" className="form-control" name="landmark" value={props.userAddress.landmark} onChange={(e) => props.handleChangeLandmark(e.target.value)} placeholder="APARTMENT / ROAD / AREA" />
                    {
                        props.errorLabelForLandmark &&
                        <label className='form-lable text-danger' htmlFor="landmark">
                            <small>{props.errorLabelForLandmark}</small>
                        </label>
                    }
                </div>
                <div className="form-group">
                    <input type="tel" className="form-control" name="phoneNumber"
                        value={props.phoneNumber} maxLength={10}
                        onChange={(e) => props.handleChangePhoneNumber(e.target.value)}
                        placeholder="Enter Phone Number" />
                    {
                        props.errorLabelForPhoneNumber &&
                        <label className='form-lable text-danger' htmlFor="phoneNumber">
                            <small>{props.errorLabelForPhoneNumber}</small>
                        </label>
                    }
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" name="email"
                        value={props.email} onChange={(e) => props.handleChangeEmail(e.target.value)}
                        placeholder="Enter email address (optional)" />
                    {
                        props.errorLabelForEmail &&
                        <label className='form-lable text-danger' htmlFor="email">
                            <small>{props.errorLabelForEmail}</small>
                        </label>
                    }
                </div>

                <div className='row'>
                    <div className="d-flex justify-content-around col-4 col- col-sm col-md col-lg col-xl">
                        <label htmlFor="address-type-home" style={{ cursor: 'pointer' }}>
                            <div className={`card adress-type-card ${props.addressCardHome}`}>
                                <div className='card-body' style={{ padding: "1rem" }}>
                                    <center>
                                        <b>Home</b><br />
                                        <i className='bi bi-house' style={{ fontSize: "1.3rem" }}></i>
                                    </center>
                                    <input
                                        style={{ display: "none" }}
                                        className="form-check-input"
                                        type="radio"
                                        name="addressType"
                                        id={`address-type-home`}
                                        value="Home"
                                        onChange={(e) => props.handleAddressType(e.target.value)}
                                    />
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="d-flex justify-content-around col-4 col- col-sm col-md col-lg col-xl">
                        <label htmlFor="address-type-office" style={{ cursor: 'pointer' }}>
                            <div className={`card adress-type-card ${props.addressCardOffice}`}>
                                <div className='card-body' style={{ padding: "1rem" }}>
                                    <center>
                                        <b>Office</b><br />
                                        <i className='bi bi-building' style={{ fontSize: "1.3rem" }}></i>
                                    </center>
                                    <input
                                        style={{ display: "none" }}
                                        className="form-check-input"
                                        type="radio"
                                        name="addressType"
                                        id={`address-type-office`}
                                        value="Office"
                                        onChange={(e) => props.handleAddressType(e.target.value)}
                                    />
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="d-flex justify-content-around col-4 col- col-sm col-md col-lg col-xl">
                        <label htmlFor="address-type-other" style={{ cursor: 'pointer' }}>
                            <div className={`card adress-type-card ${props.addressCardOther}`}>
                                <div className='card-body' style={{ padding: "1rem" }}>
                                    <center>
                                        <b>Other</b><br />
                                        <i className="bi bi-geo-fill" style={{ fontSize: "1.3rem" }}></i>
                                    </center>
                                    <input
                                        style={{ display: "none" }}
                                        className="form-check-input"
                                        type="radio"
                                        name="addressType"
                                        id={`address-type-other`}
                                        value="Other"
                                        onChange={(e) => props.handleAddressType(e.target.value)}
                                    />
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

        </>
    )
}
