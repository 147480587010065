import { RecaptchaVerifier } from "firebase/auth";
import sendOtp from "./sendOtp";
import { auth } from "./firebaseConfig";

const recaptcha = (recaptchaContainerId) => {

    window.recaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainerId,
        {
            size: "invisible",
            callback: (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                sendOtp();
            },
        },
        auth
    );
};

export default recaptcha;
