import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomePageData } from "../../redux/actions/homeActions";
import { modalContext } from "../../context/modalContext";
import { serverContext } from "../../context/serverContext";

import Skeleton from "./Skeleton";
import Carousel from "./Carousel";
import WelcomeTost from "./WelcomeTost";
import FeaturedCategory from "./FeaturedCategory";
import TopRatedProducts from "./TopRatedProducts";
import { decrypt, isDecryptedDataValid } from "../functions/cipherFunctions";
import MetaTitles from "../MetaTitles";

export default function Home() {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.homeReducer.loading);
    const exception = useSelector((state) => state.homeReducer.exception);

    const { showErrorDialog } = useContext(modalContext);
    const { serverPath } = useContext(serverContext);

    if (exception) {
        showErrorDialog(exception.message, exception.description);
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    const homePageData = decrypt(useSelector(
        (state) => state.homeReducer.homePageData
    ), true);

    const banners = isDecryptedDataValid(homePageData) && homePageData.Banners;
    const products = isDecryptedDataValid(homePageData) && homePageData.HotProducts;
    const categories = isDecryptedDataValid(homePageData) && homePageData.Categories;

    useEffect(() => {
        //scroll to top when component loads 
        window.scrollTo(0, 0);

        //fetch categories and products from API if its not already fetched
        if (!categories || !products || !banners) dispatch(getHomePageData(serverPath));

        //eslint-disable-next-line
    }, []);

    return (
        <>
        {/* Page tile and meta tags */}
        <MetaTitles title={"Home"} description={"Home page"} />

            <div className="page-content-wrapper">
                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        <Carousel banners={banners} />
                        <WelcomeTost />
                        <FeaturedCategory categories={categories} />
                        <TopRatedProducts products={products} />
                    </>
                )}
            </div>
        </>
    );
}
