import {
    FacebookAuthProvider,
    signInWithRedirect,
    setPersistence,
    browserLocalPersistence,
} from "firebase/auth";
import { auth } from "./firebaseConfig";

const facebookLoginWithRedirect = (setIsInitiatedSingIn) => {
   
    const provider = new FacebookAuthProvider();

    setPersistence(auth, browserLocalPersistence)
        .then(() => {
            //set signup processed to get user data when user redirected back after login from facebook
            sessionStorage.setItem("signUpProcessedWith", "Facebook");
            signInWithRedirect(auth, provider);
        })
        .catch((error) => {
            console.log(error);
            setIsInitiatedSingIn(false)
        });
};

export default facebookLoginWithRedirect;
