import React from 'react'
import { Link } from 'react-router-dom';

export default function NoRefunds() {
    return (
        <div className="card primary-bg-color">
            <div className="single-page-wrapper card-body">
                <div className="justify-content-center">
                    <img
                        className="illustrate "
                        src={
                            process.env.PUBLIC_URL +
                            "/bg-img/empty-cart.png"
                        }
                        alt=""
                    />

                    <h6 className='text-color mb-3'>
                        It seems like no refunds has been initiated yet,
                        Once refund will be created for any order, It will display here.
                    </h6>
                    <Link
                        className="btn theme-btn w-50"
                        to="/"
                    >
                        Order now <i className="fa fa-shopping-cart"></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}
