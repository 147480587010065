import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authContext } from '../../context/authContext'
import { modalContext } from '../../context/modalContext';
import { serverContext } from '../../context/serverContext';
import { clearOrderProcessReducersData, paymentCanceled, revisePayment, updateFailedPaymentStatus, validatePayment } from '../../redux/actions/orderProcessActions';
import { getOrderHistory, loadMoreOrderHistory, getOrderHistoryByOrderId, reFetchOrderDetails } from '../../redux/actions/orderDataActions';
import PaymentProcessingDialog from '../popupDialogs/PaymentProcessingDialog';
import CancelationPoliciesDialog from '../popupDialogs/CancelationPoliciesDialog';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import InfiniteScroll from "react-infinite-scroll-component";
import OrderSkeleton from './OrderSkeleton';
import OrderHistoryList from './OrderHistoryList';
import MetaTitles from '../MetaTitles';
import NoOrders from './NoOrders';

export default function OrderHistory() {

    const { isAuthenticate } = useContext(authContext);
    const { showErrorDialog, showSuccessDialog } = useContext(modalContext);
    const { serverPath } = useContext(serverContext);

    const [currentOrderIdForPaymentRevise, setCurrentOrderIdForPaymentRevise] = useState(null);

    const isOrderProcessed = useRef(false);
    const isLoadingMoreOrders = useRef(false);

    const dispatch = useDispatch();
    const history = useHistory();

    //get and decrypt order history data from reducer
    const getOrderHistoryData = useSelector(state => state.orderDataReducer.orderHistoryData);
    const orderHistoryList = getOrderHistoryData ? isDecryptedDataValid(decrypt(getOrderHistoryData, true)) : null;

    const countTotalOrders = useSelector(state => state.orderDataReducer.countTotalOrders);
    const pageNoOfFetchedOrder = useSelector(state => state.orderDataReducer.pageNoOfFetchedOrder);
    const countPlacedOrders = useSelector(state => state.orderDataReducer.countPlacedOrders);

    //get and decrypt shipping configurations from reducer
    const getStoreConfigsData = useSelector(state => state.generalReducer.storeConfigs);
    const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

    //get and decrypt order details data from reducer
    const getOrderDetailsFromReducer = useSelector(state => state.orderDataReducer.orderDetails);
    const orderDetailsData = getOrderDetailsFromReducer ? isDecryptedDataValid(decrypt(getOrderDetailsFromReducer, true)) : [];

    //get data about order processes from reducer
    const processedOrder = useSelector((state) => state.orderProcessReducer.processedOrder);
    const createdOrder = processedOrder?.createdOrder;

    const placedOrder = useSelector((state) => state.orderProcessReducer.placedOrder);

    const loading = useSelector(state => state.orderDataReducer.loading);
    const exception = useSelector(state => state.orderDataReducer.exception);



    //function to load more order history data
    const loadMoreOrders = () => {
        isLoadingMoreOrders.current = true;

        //set pageNo as fetchedPage + 1
        const pageNo = pageNoOfFetchedOrder + 1

        //call action to load more order history data from server
        dispatch(loadMoreOrderHistory(serverPath, isAuthenticate?.userId, orderHistoryList, pageNo, countPlacedOrders));
    }

    //show cancelation policy pop-up
    const showCancalationPolicy = (e, orderId) => {

        e.preventDefault();

        setCurrentOrderIdForPaymentRevise(orderId);

        return window.$("#cancelationPolicyModal").modal("show");
    }

    //function to show payment processing popup until order processing and creating in server
    const showPaymentProcessingNotice = (orderId) => {

        window.$("#cancelationPolicyModal").modal("hide");

        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        //show payment processing dialog
        window.$("#paymentProcessingDialog").modal("show");

        //call invokeRazorpay function after 3 seconds to initiate payment
        setTimeout(() => {
            initiatePayment(orderId);
        }, 3000);

    }

    //function to initiate payment
    const initiatePayment = (orderId) => {

        const params = {
            userId: isAuthenticate?.userId,
            orderId: orderId
        }

        //call action to process order data in server
        dispatch(revisePayment(serverPath, params));

        isOrderProcessed.current = true;
    }

    //function to invoke razorpay pop-up
    const invokeRazorpay = () => {
        if (createdOrder?.OrderId && createdOrder?.Amount && createdOrder?.RzpOrderId && createdOrder?.KeyId) {
            const options = {
                "key": createdOrder?.KeyId,
                "amount": createdOrder?.Amount,
                "currency": "INR",
                "order_id": createdOrder?.RzpOrderId,
                "name": storeConfigs?.storeName,
                "description": "Thanks for your purchase",
                "image": storeConfigs?.storeLogo,
                //handler for if payment successfully processed
                "handler": function (response) {

                    //current order id
                    response.orderId = createdOrder?.OrderId;

                    //dispatch action to validate payment
                    dispatch(validatePayment(serverPath, response));

                    isOrderProcessed.current = false
                },
                "prefill": {
                    "name": isAuthenticate.username,
                    "email": isAuthenticate.email || processedOrder.Email,
                    "contact": isAuthenticate.phoneNumber || processedOrder.Phone
                },
                "theme": {
                    "color": localStorage.getItem('theme') === 'dark' ? "#061238" : '#06227a'
                },
                "modal": {
                    //event listner to detect when customer close payment pop-up
                    "ondismiss": function () {
                        console.log('Payment pop-up closed');
                        dispatch(paymentCanceled());
                    }
                }
            };

            //show payment processing dialog
            window.$("#paymentProcessingDialog").modal("hide");

            const rzp = new window.Razorpay(options);
            rzp.open(options);


            rzp.on('payment.failed', function (response) {
                const params = {
                    orderId: createdOrder?.OrderId,
                    status: "Failed",
                    rzpOrderId: response.error?.metadata?.order_id,
                    rzpPaymentId: response.error?.metadata?.payment_id
                }

                //dispatch action to update payment status as failed for current order
                dispatch(updateFailedPaymentStatus(serverPath, params));

                isOrderProcessed.current = false;

                const reFetchHistory = true;


                //ReFetch current order details from server
                //we set delay of 1 seconds because it takes some time to clear current order details data from reducer
                setTimeout(function () {

                    //filter current order details
                    const currentOrder = orderDetailsData?.length > 0 && orderDetailsData.filter((obj) => {
                        return parseInt(obj.orderId) === parseInt(currentOrderIdForPaymentRevise);
                    })[0]?.details;

                    if (currentOrder)
                        dispatch(reFetchOrderDetails(serverPath, isAuthenticate?.userId, currentOrderIdForPaymentRevise, orderDetailsData));

                    dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, currentOrderIdForPaymentRevise, orderHistoryList, reFetchHistory));
                }, 1000);

                //clear order reducer data
                dispatch(clearOrderProcessReducersData());

            });

        } else {
            showErrorDialog(
                'Internal server error',
                'Something went wrong, Please try again after some time'
            );
        }
    }

    //scroll to top only when no orders history found
    useEffect(() => {
        if (!orderHistoryList?.length)
            window.scrollTo(0, 0);
    }, [orderHistoryList])

    //If user is not logged in then redirect to login page
    useEffect(() => {
        if (isAuthenticate === false)
            history.push('/login')

        //call action to get order history data from server
        if (isAuthenticate?.userId && orderHistoryList === null)
            dispatch(getOrderHistory(serverPath, isAuthenticate?.userId));

        //eslint-disable-next-line
    }, [isAuthenticate])

    //Monitor processedOrder to invoke razorpay popup for payment when get order data successfully from server
    useEffect(() => {
        if (isOrderProcessed.current) {
            window.$("#paymentProcessingDialog").modal("hide");
            invokeRazorpay();
        }
        //eslint-disable-next-line
    }, [processedOrder])

    //Monitor placedOrder to show success pop-up if payment is captured 
    //and order completed while revising the payment
    useEffect(() => {
        if (placedOrder?.Success) {

            //filter current order details
            const currentOrder = orderHistoryList?.length > 0 && orderHistoryList.filter((obj) => {
                return parseInt(obj.OrderId) === parseInt(currentOrderIdForPaymentRevise);
            })[0];

            showSuccessDialog(
                'Payment success',
                `Payment received sucessfully for your order ${currentOrder?.OrderNumber}`
            );

            const reFetchHistory = true;

            //ReFetch current order details and history from server
            //we set delay of 1 seconds because it takes some time to clear current order details data from reducer
            setTimeout(function () {
                //if current ordeer details found in list then reFetch ordeer details from server
                if (currentOrder)
                    dispatch(reFetchOrderDetails(serverPath, isAuthenticate?.userId, currentOrderIdForPaymentRevise, orderDetailsData));

                dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, currentOrderIdForPaymentRevise, orderHistoryList, reFetchHistory));
            }, 1000);

            //clear order reducer data
            dispatch(clearOrderProcessReducersData());
        }
        //eslint-disable-next-line
    }, [placedOrder]);


    //if more orders loaded successfully
    if (!loading && !exception && isLoadingMoreOrders.current === true) {
        isLoadingMoreOrders.current = false;
    }

    //Show error dialog for any excetion generated
    if (exception) {
        window.$("#cancelationPolicyModal").modal("hide");
        window.$("#paymentProcessingDialog").modal("hide");

        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"Order history"} description={"Users order history list"} />

            <CancelationPoliciesDialog history={history} checkoutCallback={showPaymentProcessingNotice} callBackParams={currentOrderIdForPaymentRevise} />
            <PaymentProcessingDialog />

            {!loading && isAuthenticate?.userId && parseInt(countTotalOrders) <= 0 ? <NoOrders /> :
                <div className="page-content-wrapper" >
                    <div className="row justify-content-center">
                        {
                            (loading || !isAuthenticate?.userId) && !isLoadingMoreOrders.current ? <OrderSkeleton /> :
                                <>
                                    <InfiniteScroll
                                        scrollThreshold={0.9}
                                        dataLength={
                                            Array.isArray(orderHistoryList)
                                                ? orderHistoryList.length
                                                : 0
                                        }
                                        next={loadMoreOrders}
                                        hasMore={
                                            Array.isArray(orderHistoryList) &&
                                            parseInt(orderHistoryList.length) !==
                                            parseInt(countTotalOrders)
                                        }
                                        loader={<OrderSkeleton loadMore={true} />}
                                    >
                                        <div className='row'>
                                            <OrderHistoryList orders={orderHistoryList} initiatePayment={showCancalationPolicy} />
                                        </div>
                                    </InfiniteScroll>
                                </>
                        }
                    </div>
                </div>
            }

        </>
    )
}
