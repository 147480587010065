import React from 'react'
import { useEffect } from 'react';
import MetaTitles from './MetaTitles';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function NotFound() {


    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"404 - Page not found"} description={"404 - the requested page does not found"} />

            <div className="card primary-bg-color">
                <div className="single-page-wrapper card-body">
                    <div className="justify-content-center">
                        <img
                            className="illustrate"
                            src={
                                process.env.PUBLIC_URL +
                                "/bg-img/404-illustrate.png"
                            }
                            alt=""
                        />

                        <h4 className="text-color m-3">OOPS... <br />Page not found!</h4>
                        <span className='text-color'>Even our chicken's map couldn't save this page!</span> <br />
                        <Link to="/"
                            className="btn theme-btn w-75 mt-3"
                        >
                            <i className="fa fa-arrow-left"></i> Back to home
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
