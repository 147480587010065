import React, { useContext, useEffect, useRef, useState } from 'react'
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from '../functions/firebase/firebaseConfig';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { modalContext } from '../../context/modalContext';
import EmailInputForm from './forms/EmailInputForm';
import VerifySignInLoading from './VerifySignInLoading';


export default function VarifyEmailLink() {

    const history = useHistory();

    const { showErrorDialog } = useContext(modalContext);

    //get the email from localstorage if varification link opened in same device
    const emailFromLocalStorage = window.localStorage.getItem('emailForSignIn');

    const [email, setEmail] = useState(emailFromLocalStorage || '');
    const [error, setError] = useState('');

    const [isEmailSubmitted, setIsEmailSubmitted] = useState(emailFromLocalStorage ? true : false);

    const isValidEmailAddress = useRef(emailFromLocalStorage ? true : false);

    //handle email change
    const handleEmailChange = (email) => {

        const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const isValidEmail = emailMask.test(email);

        if (email?.length === 0) {
            setError('Please enter email address');
            isValidEmailAddress.current = false;
        }
        else if (!isValidEmail) {
            isValidEmailAddress.current = false;
            setError('Enter valid email address');
        }
        else {
            isValidEmailAddress.current = true;
            setError('');
        }

        setEmail(email);
    }


    //function to handle firebase sign-in with email link
    const handleSignInWithEmailLink = () => {

        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then(() => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');

                    history.push("/user/profile");
                })
                .catch((error) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');

                    //show errror dialog
                    showErrorDialog(
                        error.message,
                        "Please ensure you are using a valid link or try signing in again. If the issue persists, contact support."
                    );

                    //redirect to login component
                    history.push("/login");
                });
        }

    }

    //handle email submit
    const handleEmailSubmit = () => {
        if (isValidEmailAddress.current) {
            setIsEmailSubmitted(true);
            //call function to sign-in
            handleSignInWithEmailLink()
        }

    }

    //call function to sign-in with email if valid email address found and isSignInWithEmailLink return true
    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href) && email && isValidEmailAddress.current) {
            //set 1 seconds delay to call handleSignInWithEmailLink as signInWithEmailLink inside the handleSignInWithEmailLink is taking some time to load
            setTimeout(() => {
                handleSignInWithEmailLink();
            }, 1000)
        }
        //eslint-disable-next-line
    }, [])

    return !isValidEmailAddress.current || !isEmailSubmitted ? <EmailInputForm email={email} handleEmailChange={handleEmailChange} error={error} handleEmailSubmit={handleEmailSubmit} /> : <VerifySignInLoading />
}
