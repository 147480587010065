import {
    BEGIN_ACTION_IN_PRODUCT_REDUCER,
    SUCCESS_GET_PRODUCTS,
    EXCEPTION_GENERATED_IN_PRODUCT_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
    SET_FETCHED_PRODUCTS_CATEGORY_IDS,
    SET_LOADED_PAGENO_OF_PRODUCTS,
    UPDATE_LOADED_PAGENO_OF_PRODUCTS,
} from "../constants";

const initialState = {
    loading: false,
    productData: [],
    noCategoryFound: false,
    exception: false,
    fetchedProductsCategories: [],
    loadedPageOfProducts: [],
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_PRODUCT_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case SUCCESS_GET_PRODUCTS:
            return {
                ...state,
                loading: false,
                exception: false,
                noCategoryFound: action.payload?.NoCategoryFound || false,
                productData: [...state.productData, action.payload],
            };

        case SET_FETCHED_PRODUCTS_CATEGORY_IDS:
            return {
                ...state,
                loading: false,
                exception: false,
                noCategoryFound: false,
                fetchedProductsCategories: [
                    ...state.fetchedProductsCategories,
                    action.payload,
                ],
            };
        case SET_LOADED_PAGENO_OF_PRODUCTS:
            return {
                ...state,
                loading: false,
                exception: false,
                noCategoryFound: false,
                loadedPageOfProducts: [
                    ...state.loadedPageOfProducts,
                    action.payload,
                ],
            };

        case UPDATE_LOADED_PAGENO_OF_PRODUCTS:
            return {
                ...state,
                loading: false,
                exception: false,
                noCategoryFound: false,
                loadedPageOfProducts: [
                    ...state.loadedPageOfProducts.map((obj) => {
                        if (obj.categoryId === action.payload.categoryId)
                            return {
                                categoryId: action.payload.categoryId,
                                loadedPage: action.payload.loadedPage,
                            };
                        else return obj;
                    }),
                ],
            };

        case EXCEPTION_GENERATED_IN_PRODUCT_REDUCER:
            return {
                ...state,
                loading: false,
                noCategoryFound: false,
                exception: action.payload,
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
};

export default productReducer;
