import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function NoMethods() {
    return (
        <div className="card">
            <div className="text-center card-body">
                <div className="justify-content-center">
                    <img
                        className="illustrate "
                        src={
                            process.env.PUBLIC_URL +
                            "/bg-img/otp-illustration.png"
                        }
                        alt=""
                    />

                    <h6 className="text-color mb-5 mt-5">
                        We’re sorry, but there are no sign-in options available at the moment. This might be due to a temporary issue or ongoing updates to our system. Please check back later, and thank you for your patience and understanding!
                    </h6>

                    <Link
                        className="btn btn-warning w-50"
                        to="/"
                    >
                        <i className="fa fa-arrow-left mx-2"></i>Homepage
                    </Link>
                </div>
            </div>
        </div>
    )
}
