import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

let firebaseApp = '';
let auth = '';
let allowedLoginOptions = '';

// Function to fetch configuration and initialize Firebase
export const initializeFirebase = async (serverPath) => {
    try {
        const response = await axios.get(`${serverPath}/get-firebaseConfig`);
        const firebaseConfigs = response.data.Config;
        const allowedMethods = response.data.AllowedMethods

        if (!firebaseApp) {
            firebaseApp = initializeApp(firebaseConfigs);
            auth = getAuth(firebaseApp);
            allowedLoginOptions = allowedMethods;
        }
    } catch (error) {
        console.error("Failed to fetch Firebase configuration:", error);
    }
};

// Export Firebase app and auth for use after initialization
export { firebaseApp, auth, allowedLoginOptions };
