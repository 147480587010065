import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MetaTitles from "../MetaTitles";

export default function EmailLinkSentSuccess() {

    const history = useHistory();
    const email = window.localStorage.getItem('emailForSignIn');

    if (!email)
        history.push("/login");

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"Email verification link sent"} description={"Email verification link sent successfully for sign-in"} />

            <div className="page-content-wrapper">
                <div className="login-wrapper d-flex align-items-center justify-content-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">
                                <div className="text-center px-2">
                                    <img
                                        className="illustrate"
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/bg-img/otp-illustration.png"
                                        }
                                        alt=""
                                    />
                                    <p className="mt-5">{email}</p>
                                    <h5><span className="text-success">Verification link sent successfully</span></h5>
                                    <p>Kindly check your email inbox and click the verification link to proceed with signing in.
                                        We recommend to open the verification link on same device for better experiance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


