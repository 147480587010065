import React from "react";

function SuccessDialog() {
    return (
        <div
            className="modal fade dialogbox"
            id="successDialog"
            data-bs-backdrop="static"
            tabIndex="-1"
            role="dialog"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-icon text-success">
                        <i className="fa fa-check-circle"></i>
                    </div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="successTitle">
                            Sucess
                        </h5>
                    </div>
                    <div className="modal-body">
                        <p id="successMessage">Operatin success</p>
                    </div>
                    <div className="text-center mb-3">
                        <button className="btn btn-success w-50" data-bs-dismiss="modal">CLOSE</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessDialog;
