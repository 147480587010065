import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { modalContext } from "../../context/modalContext";
import { authContext } from "../../context/authContext";
import { getRedirectResult, sendSignInLinkToEmail } from "firebase/auth";
import { serverContext } from "../../context/serverContext";
import { auth, allowedLoginOptions, initializeFirebase } from "../functions/firebase/firebaseConfig";

import googleLoginWithRedirect from "../functions/firebase/loginWithGoogle";
import googleLoginWithPopup from "../functions/firebase/loginWithGooglePopup";
import facebookLoginWithRedirect from "../functions/firebase/loginWithFacebook";

import recaptcha from "../functions/firebase/recaptcha";
import sendOtp from "../functions/firebase/sendOtp";
import MetaTitles from "../MetaTitles";

import FacebookSignInBtn from "./loginBtns/FacebookSignInBtn";
import GoogleSignInBtn from "./loginBtns/GoogleSignInBtn";
import PhoneVerification from "./forms/PhoneVerification";
import EmailVerification from "./forms/EmailVerification";
import EmailSignInBtn from "./loginBtns/EmailSignInBtn";
import PhoneSignInBtn from "./loginBtns/PhoneSignInBtn";

import NoMethods from "./NoMethods";
import VerifySignInLoading from "./VerifySignInLoading";


function Login() {
    const validPhone = useRef(false);
    const validEmail = useRef(false);

    const [errorLabelForPhone, setErrorLabelForPhone] = useState("");
    const [errorLabelForEmail, setErrorLabelForEmail] = useState("");

    const { showErrorDialog, showLoading, hideLoading } = useContext(modalContext);
    const { isAuthenticate, phoneNumber, setPhoneNumber } = useContext(authContext);

    const { serverPath } = useContext(serverContext);

    const [isFirebaseReady, setIsFirebaseReady] = useState(auth ? true : false);
    const [email, setEmail] = useState('');
    const [activeLoginOption, setActiveLoginOption] = useState('');
    const [isInitiatedSingIn, setIsInitiatedSingIn] = useState(false);

    const [googleLoginMethodType, setGoogleLoginMethodType] = useState('redirect');

    const history = useHistory();

    //set default active login option email/phone
    useEffect(() => {
        if (allowedLoginOptions && Object.values(allowedLoginOptions).includes("email"))
            setActiveLoginOption('email')
        else if (allowedLoginOptions && Object.values(allowedLoginOptions).includes("phone"))
            setActiveLoginOption('phone')
        else
            setActiveLoginOption('')

        //set google sign-in type as pop-up if usePopUpMethod found 
        if (allowedLoginOptions && Object.values(allowedLoginOptions).includes("usePopUpMethodForGoogle"))
            setGoogleLoginMethodType("pop-up");

        //eslint-disable-next-line
    }, [allowedLoginOptions])

    //fetch firebase config and initilize firebase app
    useEffect(() => {
        if (!auth) {
            const initFirebase = async () => {
                await initializeFirebase(serverPath);
                setIsFirebaseReady(true);
            };

            initFirebase();
        }
    }, [serverPath]);

    //function to wait until users are authenticating when redirected back afetr login from Facebook/Google
    const waitUntilAuthenticate = async () => {
        //complete sign up process / remove it from session storage
        sessionStorage.removeItem("signUpProcessedWith");

        showLoading();

        await getRedirectResult(auth)
            .then((res) => {
                if (!res)
                    showErrorDialog("Sign-in failed", "Please try again or use another available method to continue.")

                return true;
            })
            .catch((error) => {
                showErrorDialog(
                    "Internal server error",
                    error.message
                );
            });
        hideLoading();
    };

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //if user already loged in then redirect users to home page
        if (isAuthenticate) history.push("/user/profile");

        if (isFirebaseReady) {
            //call firebase function to create ReCaptcha to send otp
            recaptcha("recaptcha-container");

            //call function to show loading until we get authenticated users data
            //when user redirected back after login from facebook/google
            if (sessionStorage.getItem("signUpProcessedWith")) {
                setIsInitiatedSingIn(false);
                waitUntilAuthenticate();
            }
        }
        //eslint-disable-next-line
    }, [isAuthenticate]);

    //function to check entered phone number is valid or not
    const isValidPhoneNumber = (phoneNumber) => {

        //check is entered phonr number is valid or not  
        const isValid = /^[0-9]+$/.test(phoneNumber)

        //return if entered value is not a numbers
        if (phoneNumber.length > 0 && !isValid)
            return;

        if (phoneNumber.length > 10)
            return;

        if (phoneNumber.length === 0)
            setErrorLabelForPhone('');
        else if (phoneNumber.length < 10 || !isValid)
            setErrorLabelForPhone('Enter valid phone number');
        else
            setErrorLabelForPhone('');

        setPhoneNumber(phoneNumber);
        validPhone.current = true;
    };

    //handle email change
    const handleEmailChange = (email) => {

        const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const isValidEmail = emailMask.test(email);

        if (email?.length === 0 || isValidEmail) {
            validEmail.current = true;
            setErrorLabelForEmail('')
        }
        else {
            validEmail.current = false;
            setErrorLabelForEmail('Enter valid email address');
        }

        setEmail(email);
    }

    //function to handle login from facebook/google
    const handleFacebookAndGoogleLogin = (loginProvider) => {
        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        setIsInitiatedSingIn(true);

        //call provider function to login
        loginProvider(setIsInitiatedSingIn);
    };


    //function to login with email link
    const handleLoginWithEmail = async () => {

        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }


        if (validEmail.current) {

            showLoading();

            const actionCodeSettings = {
                //callback url to redirect back when user click on email link
                url: window.location.origin + '/verify-email',
                handleCodeInApp: true,
            };

            await sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {

                    // Save the email locally so you don't need to ask the user for it again
                    // if they open the link on the same device.
                    window.localStorage.setItem('emailForSignIn', email);

                    //redirect to success page
                    history.push("/verification-link-sent")

                })
                .catch((error) => {
                    showErrorDialog(
                        "Could not send verification",
                        error.message
                    );
                    console.log('error: ', error.message);
                });

            hideLoading();
        } else {
            showErrorDialog(
                "Invalid email address",
                "Please enter valid email address and try again"
            );
        }

    }

    //function to handle submition of phone number to send otp
    const handleLoginWithPhone = async () => {
        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        if (validPhone.current === true) {
            showLoading();

            //call firebase function to send otp
            const isOtpSent = await sendOtp(phoneNumber);

            hideLoading();

            if (isOtpSent === true) history.push("/confirm-otp");
            else {
                showErrorDialog(
                    "OTP does not sent",
                    "Something went wrong, <br />Please make sure you have entered valid phone number and then try again"
                );

                setPhoneNumber("");
            }
        }
        else {
            showErrorDialog(
                "Invalid phone number",
                "Please enter valid phone number and try again"
            );
        }
    };

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"User login"} description={"Users login"} />

            {isInitiatedSingIn ? <VerifySignInLoading /> :
                <div className="page-content-wrapper">
                    <div className=" card" style={{ minHeight: "100vh" }}>
                        <div className="row justify-content-center card-body login-card">
                            <div className="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">

                                <div id="recaptcha-container"></div>

                                {allowedLoginOptions ?
                                    <>
                                        <div className="text-center px-2">
                                            <div className={activeLoginOption === "" ? 'pt-5' : ''}>
                                                {
                                                    activeLoginOption === "" &&
                                                    <div className="mt-5">
                                                        <p >Click the button below to sign in using the available option(s) like Google or Facebook</p>
                                                    </div>
                                                }
                                                <GoogleSignInBtn isEnabled={allowedLoginOptions && Object.values(allowedLoginOptions).includes("google")} handleFacebookAndGoogleLogin={handleFacebookAndGoogleLogin} googleLoginType={googleLoginMethodType === "redirect" ? googleLoginWithRedirect : googleLoginWithPopup} />
                                                <FacebookSignInBtn isEnabled={allowedLoginOptions && Object.values(allowedLoginOptions).includes("facebook")} handleFacebookAndGoogleLogin={handleFacebookAndGoogleLogin} facebookLoginWithRedirect={facebookLoginWithRedirect} />
                                            </div>

                                            {activeLoginOption === 'phone' && <EmailSignInBtn isEnabled={allowedLoginOptions && Object.values(allowedLoginOptions).includes("email")} setActiveLoginOption={setActiveLoginOption} />}
                                            {activeLoginOption === 'email' && <PhoneSignInBtn isEnabled={allowedLoginOptions && Object.values(allowedLoginOptions).includes("phone")} setActiveLoginOption={setActiveLoginOption} />}

                                            {
                                                // print OR only when more than one sign-in methods avaibale 
                                                Object.keys(allowedLoginOptions).length > 1 &&
                                                (Object.keys(allowedLoginOptions).length !== 2 || !Object.values(allowedLoginOptions).includes("usePopUpMethodForGoogle")) &&
                                                (activeLoginOption === "phone" || activeLoginOption === "email") &&
                                                <div className=" d-block justify-content-center py-3">
                                                    <strong>or</strong>
                                                </div>
                                            }

                                            <div className={Object.keys(allowedLoginOptions).length === 1 ? 'pt-5' : ''}>
                                                {activeLoginOption === 'email' && <EmailVerification isEnabled={allowedLoginOptions && Object.values(allowedLoginOptions).includes("email")} email={email} errorLabelForEmail={errorLabelForEmail} handleEmailChange={handleEmailChange} handleEmailSubmit={handleLoginWithEmail} />}
                                                {activeLoginOption === 'phone' && <PhoneVerification isEnabled={allowedLoginOptions && Object.values(allowedLoginOptions).includes("phone")} phoneNumber={phoneNumber} errorLabelForPhone={errorLabelForPhone} isValidPhoneNumber={isValidPhoneNumber} handleLoginWithPhone={handleLoginWithPhone} />}
                                            </div>


                                            {activeLoginOption === "" && <div className="login-meta-data px-2 text-center">
                                                <p className="mt-5 mb-2">
                                                    By clicking on above button, I hereby agree the
                                                    <Link
                                                        className="mx-1 stretched-link"
                                                        to="/terms-and-conditions"
                                                    >
                                                        Term of Services
                                                    </Link>
                                                    and
                                                    <Link
                                                        className="mx-1 stretched-link"
                                                        to="/privacy-policy"
                                                    >
                                                        Privacy Policy.
                                                    </Link>
                                                </p>
                                            </div>}

                                        </div>
                                    </> :
                                    <NoMethods />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Login;
