import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function RefundItems({ refundItems }) {

    let refundData = '';

    if (refundItems?.length > 0) {
        refundData = refundItems?.map(item => {
            return (
                <div className='col-sm-6' key={item.RefundNumber}>
                    <div className='card refund-card mb-2 m-1'>
                        <div className='card-body'>
                            <Link to={`/refund/status/${item.RefundId}`}>
                                <div className='row text-start mb-2'>
                                    <h6><small>Issued on : </small><span className='ms-1'>{item.CreatedAt}</span></h6>
                                    <h6><small>Order Amount : </small><span className='ms-1'>₹ {item.OrderAmount}</span></h6>
                                </div>
                                <div className='row'>
                                    <div className='col-5 col-md-6 col-lg-6'>
                                        <h6>Refund ID</h6>
                                        <h6>Order ID</h6>
                                        <h6>Status</h6>
                                    </div>
                                    <div className='col-7 col-md-6 col-lg-6' style={{ textAlign: 'end' }}>
                                        <h6>#{item.RefundNumber}</h6>
                                        <h6>{item.OrderNumber}</h6>
                                        <h6 className='fw-bold'>{item.Status}</h6>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return refundData;

}
