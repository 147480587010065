import {
    BEGIN_ACTION_IN_GENERAL_REDUCER,
    EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
    SUCCESS_GET_STORE_CONFIGS,
    SUCCESS_GET_ABOUT_US_STATUS,
    SUCCESS_GET_ABOUT_US_CONTENT,
    SUCCESS_GET_TERMS_AND_CONDITIONS_CONTENT,
    SUCCESS_GET_PRIVACY_POLICY_CONTENT,
    SUCCESS_GET_STORE_CACHE_STATE,

} from "../constants";
import axios from "axios";
import { encrypt, hasJsonStructure } from "../../components/functions/cipherFunctions";

export function getStoreConfigs(serverPath) {
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_GENERAL_REDUCER
        });

        axios
            .get(serverPath + "/get-store-configs")
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_STORE_CONFIGS,
                    payload: encrypt(res.data, true),
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function getCacheState(serverPath) {
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_GENERAL_REDUCER
        });

        axios
            .get(serverPath + "/cache-state")
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_STORE_CACHE_STATE,
                    payload: encrypt(res.data, true),
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function getAboutUsStatus(serverPath) {

    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_GENERAL_REDUCER,
        });

        axios
            .get(serverPath + "/get-about-us-status")
            .then((res) => {

                dispatch({
                    type: SUCCESS_GET_ABOUT_US_STATUS,
                    payload: res.data,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    };
}

export function getAboutUsContent(serverPath) {

    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_GENERAL_REDUCER,
        });

        axios
            .get(serverPath + "/get-about-us-content")
            .then((res) => {

                dispatch({
                    type: SUCCESS_GET_ABOUT_US_CONTENT,
                    payload: res.data,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    };
}

export function getTermsAndConditionContent(serverPath) {

    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_GENERAL_REDUCER,
        });

        axios
            .get(serverPath + "/get-terms-content")
            .then((res) => {

                dispatch({
                    type: SUCCESS_GET_TERMS_AND_CONDITIONS_CONTENT,
                    payload: res.data,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    };
}

export function getPrivacyAndPolicyContent(serverPath) {

    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_GENERAL_REDUCER,
        });

        axios
            .get(serverPath + "/get-privacy-content")
            .then((res) => {

                dispatch({
                    type: SUCCESS_GET_PRIVACY_POLICY_CONTENT,
                    payload: res.data,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_GENERAL_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    };
}