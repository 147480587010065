import React, { useContext, useEffect } from "react";
import MetaTitles from "./MetaTitles";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authContext } from "../context/authContext";
import { cartItemContext } from "../context/cartItemContext";
import { clearOfferReducersData } from "../redux/actions/offerActions";
import { clearOrderProcessReducersData } from "../redux/actions/orderProcessActions";
import { clearOrderDataReducersData } from "../redux/actions/orderDataActions";
import { clearAddressReducersData } from "../redux/actions/addressActions";
import { clearRefundReducerData } from "../redux/actions/refundActions";
import { getAboutUsStatus } from "../redux/actions/generalActions";
import { serverContext } from "../context/serverContext";
import { modalContext } from "../context/modalContext";


const ConfirmLogout = ({ callBack }) => {
	return (
		<div className="modal fade dialogbox" id="confirmLogoutDialog" data-bs-backdrop="static" tabIndex="-1" role="dialog">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title text-danger">Want to logout ?</h5>
					</div>
					<div className="modal-body">
						<p>Logging out will clear the current products from the cart, and any ongoing order process.</p>
					</div>
					<div className="modal-footer">
						<div className="btn-inline">
							<button className="btn pt-3 text-color" data-bs-dismiss="modal">Cancle</button>
							<button className="btn pt-3 text-danger" onClick={() => callBack()}>Logout</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default function Settings() {

	const history = useHistory();
	const dispatch = useDispatch();
	const { isAuthenticate, logOut } = useContext(authContext);
	const { clearCartItems } = useContext(cartItemContext);
	const { serverPath } = useContext(serverContext);
	const { showErrorDialog } = useContext(modalContext);

	const isAboutUsEnabled = useSelector(state => state.generalReducer.isAboutUsEnabled);
	const exception = useSelector((state) => state.generalReducer.exception);

	//scroll to top when component loads 
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])


	useEffect(() => {
		if (!isAboutUsEnabled)
			dispatch(getAboutUsStatus(serverPath));
		// eslint-disable-next-line
	}, [])


	const showConfirmLogout = () => {
		window.$("#confirmLogoutDialog").modal("show");
	}

	const handleLogout = async () => {

		window.$("#confirmLogoutDialog").modal("hide");

		const isLogout = await logOut();

		if (isLogout === true) {

			//clear reducers data
			dispatch(clearOfferReducersData());
			dispatch(clearOrderProcessReducersData(true));
			dispatch(clearOrderDataReducersData());
			dispatch(clearAddressReducersData());
			dispatch(clearRefundReducerData());

			clearCartItems();

			history.push("/");
		}
	};

	if (exception) {
		showErrorDialog(
			exception.message,
			exception.description
		);
		dispatch({
			type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
		});
	}


	return (
		<>
			{/* Page tile and meta tags */}
			<MetaTitles title={"Settings"} description={"Users settings"} />

			<ConfirmLogout callBack={handleLogout} />

			<div className="page-content-wrapper py-1">
				<div className="container">
					<div className="card mb-3 shadow-sm">
						<div className="card-body">
							<p>Help & Support</p>

							{isAboutUsEnabled &&
								<div className="single-setting-panel"><Link to="/about-us">
									<div className="icon-wrapper"><i className="bi bi-info-circle"></i></div>About Us</Link></div>
							}

							<div className="single-setting-panel"><Link to="/privacy-policy">
								<div className="icon-wrapper"><i className="bi bi-shield-exclamation"></i></div>Privacy Policy</Link></div>
							<div className="single-setting-panel"><Link to="/terms-and-conditions">
								<div className="icon-wrapper"><i className="bi bi-clipboard-check"></i></div>Terms and Conditions</Link></div>
							<div className="single-setting-panel"><Link to="/instruction-clear-browser-data">
								<div className="icon-wrapper"><i className="bi bi-info-square"></i></div>How to clear browser data</Link></div>

						</div>
					</div>

					{
						isAuthenticate &&
						<div className="card shadow-sm">
							<div className="card-body">
								<div className="single-setting-panel"><Link to="#" onClick={() => showConfirmLogout()}>
									<div className="icon-wrapper bg-danger"><i className="bi bi-box-arrow-right"></i></div>Logout</Link></div>
							</div>
						</div>
					}
				</div>
			</div>
		</>
	)
}
