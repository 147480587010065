import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import { useContext } from 'react';
import { authContext } from '../../context/authContext';
import { serverContext } from '../../context/serverContext';
import { modalContext } from '../../context/modalContext';
import { getRefundDetails } from '../../redux/actions/refundActions';
import Details from './Details';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import RefundDetailsSkeleton from './Skeleton';
import StatusHistory from './StatusHistory';
import RefundTotals from './refundTotals';
import PaymentDetails from './PaymentDetails';
import MetaTitles from '../MetaTitles';

export default function RefundDetails() {

    const { refundId } = useParams();

    const dispatch = useDispatch();
    const { isAuthenticate } = useContext(authContext);
    const { serverPath } = useContext(serverContext);
    const { showErrorDialog } = useContext(modalContext);

    const getRefundDetailsFromReducer = useSelector(state => state.refundReducer.refundDetails);
    const refundDetails = getRefundDetailsFromReducer ? isDecryptedDataValid(decrypt(getRefundDetailsFromReducer, true)) : null;
    const loading = useSelector(state => state.refundReducer.loading);
    const exception = useSelector(state => state.refundReducer.exception);

    //filter current requested refunds status history
    const currentRefundDetails = refundDetails?.length && refundDetails.filter(item => {
        return parseInt(item.RefundDetails.RefundId) === parseInt(refundId);
    })[0];

    useEffect(() => {
        if (isAuthenticate?.userId && !currentRefundDetails?.RefundDetails?.RefundId) {
            const params = {
                userId: isAuthenticate?.userId,
                refundId: refundId
            }

            dispatch(getRefundDetails(serverPath, params, refundDetails))
        }
        //eslint-disable-next-line
    }, [isAuthenticate])

    //scroll to top when page loads
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //Show error dialog for any excetion generated
    if (exception) {

        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    return (
        <>
            {/* Page tile and meta tags */}
            <MetaTitles title={"Order refund details"} description={"Users order refund details"} />

            <div className="page-content-wrapper">
                <div className="row justify-content-center">
                    <div className="row">
                        {
                            loading || !isAuthenticate?.userId ? <RefundDetailsSkeleton /> : <>
                                <div className='col-sm-6'>
                                    <Details details={currentRefundDetails?.RefundDetails} />
                                    <StatusHistory statusHistory={currentRefundDetails?.StatusHistory} />
                                </div>

                                <div className='col-sm-6'>
                                    <PaymentDetails paymentDetails={currentRefundDetails?.PaymentDetails} paymentStatus={currentRefundDetails?.RefundDetails.PaymentStatus} rzpRefundId={currentRefundDetails?.RefundDetails.RzpRefundId} noDetails={currentRefundDetails?.NoPaymentDetails} />
                                    <RefundTotals refundTotals={currentRefundDetails?.RefundDetails} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
